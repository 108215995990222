import { React, useState, useRef, useEffect, useContext } from "react";
import Edit_light from "../../assets/Edit_light.svg";
import { Switch } from "antd";
import DropdownList from "./DropdownList";
import plusSign from "../../assets/plusSign.svg";
import AddModal from "./AddModal";
import RemoveModal from "./RemoveModal";
import axios from "axios";
import toast from "react-hot-toast";
import { AuthContext } from "../../auth/AuthProvider";
import { getCategoryNameById } from "../../utils";
import ProductAddOnModal from "./productAddOnModal";
import { set } from "firebase/database";

function ProductEditTable({ data, setData }) {
  const [isEditing, setIsEditing] = useState(false);
  const { user, token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const inputRef = useRef(null);
  const [categoryValue, setCategoryValue] = useState("Choose");
  const [showAddOnModal, setShowAddOnModal] = useState(false);
  const handleShowAddOnModal = () => setShowAddOnModal(true);
  const handleCloseAddOnModal = () => setShowAddOnModal(false);


  const [inputValue, setInputValue] = useState(data.productName);
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  const [showRemove, setShowRemove] = useState(false);
  const handleCloseRemove = () => setShowRemove(false);
  const handleShowRemove = () => setShowRemove(true);


  const boldStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    lineHeight: "24.75px",
    color: " rgba(18, 20, 30, 1)",
  };

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: " rgba(18, 20, 30, 1)",
  };

  const onChangeDisable = () => {
    setData((prevData) => ({
      ...prevData,
      isDisabled: !prevData.isDisabled,
    }));
  };

  const onChangePrint = (checked) => {
    setData({ ...data, isPrinted: checked });
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleEditClick = () => {
    setInputValue(data.productName);
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSaveClick = () => {
    setData({ ...data, productName: inputValue });
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setInputValue(data.productName);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const value =
      e.target.id === "cost" || e.target.id === "price"
        ? parseFloat(e.target.value)
        : e.target.value;
    setData({ ...data, [e.target.id]: value });
  };

  const handleAddStock = (value) => {
    const newValue = data.stock + value;
    setData({ ...data, stock: newValue });
  };

  const handleRemoveStock = (value) => {
    let newValue = data.stock - value;
    if (newValue < 0) {
      newValue = 0;
    }
    setData({ ...data, stock: newValue });
  };

  const getDropdownItems = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/category/getAll`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedCategories = response.data.data.sort((a, b) => a.pos - b.pos);
      setCategories(sortedCategories);
    } catch (error) {
      toast.error("Failed to fetch categories");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDropdownItems();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      const categoryName = getCategoryNameById(data.categoryId, categories);
      setCategoryValue(categoryName);
    }
  }, [categories, data.categoryId]);

 

  return (
    <>
      <div className="d-flex flex-column w-[90%] h-100">
        <div className="d-flex justify-between items-center h-[16.5%] ">
          <div className="d-flex items-center h-[36px] w-[300px]">
            {isEditing ? (
              <>
                <input
                  ref={inputRef}
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{
                    width: "170px",
                    marginRight: "10px",
                    fontWeight: "600",
                    outline: "none",
                    borderBottom: "1.5px solid rgba(234, 234, 234, 1)",
                  }}
                />

                <button
                  onClick={handleSaveClick}
                  style={{
                    marginRight: "10px",
                    width: "45px",
                    height: "25px",
                    fontSize: "12px",
                    backgroundColor: "#E06F2C",
                    color: "#fff",
                    borderRadius: "4px",
                  }}
                >
                  Save
                </button>

                <button
                  onClick={handleCancelClick}
                  style={{
                    height: "25px",
                    width: "50px",
                    fontSize: "12px",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "4px",
                  }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                {data.productName === "" ? (
                  <div
                    className="d-flex items-center justify-center w-[250px] h-[35px] "
                    style={{ background: "#f8f8f8", borderRadius: "9px" }}
                    onClick={handleEditClick}
                  >
                    <input
                      placeholder="Product Title"
                      style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#f8f8f8",
                        paddingLeft: "5px",
                        width: "190px",
                      }}
                      className="custom-placeholder"
                    />
                    <button className="w-[24px] h-[24px]">
                      <img src={Edit_light} alt="edit" />
                    </button>
                  </div>
                ) : (
                  <div
                    className="h-[36px] text-align-center me-[10px] overflow-hidden"
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "36px",
                      color: " rgba(18, 20, 30, 1)",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "260px",
                    }}
                  >
                    {data.productName}
                  </div>
                )}
              </>
            )}
            {data.productName !== "" && !isEditing ? (
              <button className="w-[24px] h-[24px]" onClick={handleEditClick}>
                <img src={Edit_light} alt="edit" />
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex h-[40px] items-center me-[15px]">
            <div className="me-[12px]" style={normalStyle}>
              Disable
            </div>
            <div>
              <Switch
                className={`${data.isDisabled ? "" : "custom-switch"}`}
                onChange={onChangeDisable}
                checked={data.isDisabled}
                style={{
                  backgroundColor: data.isDisabled
                    ? "rgba(224, 111, 44, 1)"
                    : "rgba(248, 248, 248, 1)",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ border: "1.5px solid rgba(234, 234, 234, 1)" }} />

        <div className="d-flex justify-between items-center h-[16.5%] ">
          <div style={boldStyle}>Category</div>
          <div className="me-[15px]">
            <DropdownList
              value={categoryValue}
              items={categories}
              data={data}
              setData={setData}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div style={{ border: "1.5px solid rgba(234, 234, 234, 1)" }} />

        <div className="d-flex items-center justify-between h-[16.5%] ">
          <div style={boldStyle}>Cost</div>
          <div
            className="w-[90px] h-[30px] d-flex justify-center items-center me-[15px]"
            style={{
              ...normalStyle,
              backgroundColor: "#f8f8f8",
              borderRadius: "7px",
            }}
          >
            <div className="flex items-center">
              <div className="relative w-full h-[30px]">
                <div
                  className="absolute left-[16px] top-1/2 transform -translate-y-1/2"
                  style={{ color: "#9C9C9C", fontSize: "16px" }}
                >
                  &#36;
                </div>

                <input
                  className="w-full h-full px-[18px] focus:outline-none pl-[32px]"
                  style={{ borderRadius: "5px", background: "#F8F8F8" }}
                  id="cost"
                  value={data.cost}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ border: "1.5px solid rgba(234, 234, 234, 1)" }} />

        <div className="d-flex items-center justify-between h-[16.5%] ">
          <div style={boldStyle}>Price</div>
          <div
            className="w-[90px] h-[30px] d-flex justify-center items-center me-[15px]"
            style={{
              ...normalStyle,
              backgroundColor: "#f8f8f8",
              borderRadius: "7px",
            }}
          >
            <div className="flex items-center">
              <div className="relative w-full h-[30px]">
                <div
                  className="absolute left-[16px] top-1/2 transform -translate-y-1/2"
                  style={{ color: "#9C9C9C", fontSize: "16px" }}
                >
                  &#36;
                </div>

                <input
                  className="w-full h-full px-[18px] focus:outline-none pl-[32px]"
                  style={{ borderRadius: "5px", background: "#F8F8F8" }}
                  id="price"
                  value={data.price}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ border: "1.5px solid rgba(234, 234, 234, 1)" }} />

        <div className="d-flex items-center justify-between h-[16.5%] ">
          <div style={boldStyle}>Stock</div>
          <div className="d-flex w-[100px] justify-end items-center">
            <button
              className="w-[12px] h-[12px] me-[10px]"
              onClick={handleShowAdd}
            >
              <img src={plusSign} alt="+" />
            </button>
            <div
              className="w-[64px] h-[30px] d-flex justify-center items-center me-[15px]"
              style={{
                ...normalStyle,
                color: "#fff",
                backgroundColor: "#E06F2C",
                borderRadius: "7px",
              }}
            >
              {data.stock}
            </div>
          </div>
        </div>
        <div style={{ border: "1.5px solid rgba(234, 234, 234, 1)" }} />

        <div className="d-flex items-center justify-between h-[16.5%] ">
          <div className="flex gap-2">
            <div style={boldStyle}>Print Receipt</div>
            <div className="me-[15px]">
              <Switch
                className={`${data.isPrinted ? "" : "custom-switch"} ml-10`}
                onChange={onChangePrint}
                checked={data.isPrinted}
                style={{
                  backgroundColor: data.isPrinted
                    ? "rgba(224, 111, 44, 1)"
                    : "rgba(248, 248, 248, 1)",
                }}
              />
            </div>
          </div>
          <div className="text-[#EAEAEA]">|</div>
          <button className="px-10 bg-[#f8f8f8] py-1 rounded" onClick={handleShowAddOnModal}>
             Manage Add-ons
          </button>
        </div>

        <AddModal
          show={showAdd}
          setShow={setShowAdd}
          handleClose={handleCloseAdd}
          handleShowRemove={handleShowRemove}
          handleAddStock={handleAddStock}
          data={data}
        />
        <RemoveModal
          show={showRemove}
          setShow={setShowRemove}
          handleClose={handleCloseRemove}
          handleShowAdd={handleShowAdd}
          handleRemoveStock={handleRemoveStock}
          data={data}
        />
        <ProductAddOnModal 
        show={showAddOnModal}
        setShow={setShowAddOnModal}
        handleClose={handleCloseAddOnModal}
        data={data}
        setData={setData}
          />
      </div>
      <style jsx>{`
        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
    </>
  );
}

export default ProductEditTable;
