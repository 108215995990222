import React, { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { AuthContext } from "../../auth/AuthProvider";
import { Spinner } from '@chakra-ui/react'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const BarChart = () => {
  const [chartData, setChartData] = useState(null);
  const [yAxisOptions, setYAxisOptions] = useState({ max: 200, stepSize: 100 });
  const { dashboardSalesAndProfits } = useContext(AuthContext);
  const weeklyData = dashboardSalesAndProfits;
  function calculateYAxisOptions(chartData) {
    if (!chartData || !chartData.datasets) return { max: 200, stepSize: 100 };

    const allValues = chartData.datasets.flatMap((dataset) => dataset.data);
    const maxValue = Math.max(...allValues);

    let stepSize, max;

    if (maxValue <= 100) {
      stepSize = 20;
      max = 100;
    } else if (maxValue <= 500) {
      stepSize = 100;
      max = 500;
    } else if (maxValue <= 1000) {
      stepSize = 200;
      max = 1000;
    } else if (maxValue <= 5000) {
      stepSize = 1000;
      max = 5000;
    } else {
      stepSize = 2000;
      max = Math.ceil(maxValue / 2000) * 2000;
    }

    return { max, stepSize };
  }

  
  useEffect(() => {
    if (!weeklyData || !Array.isArray(weeklyData) || weeklyData.length === 0) {
      // If weeklyData is not available or not in the expected format, return early
      return;
    }

    const daysOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const today = new Date().getDay();
    const todayName = daysOrder[today === 0 ? 6 : today - 1]; // Convert 0-6 to day name

    // Sort the data so that today is last
    const sortedData = [...weeklyData]?.sort((a, b) => {
      const aIndex = daysOrder.indexOf(a.day);
      const bIndex = daysOrder.indexOf(b.day);
      const todayIndex = daysOrder.indexOf(todayName);
      
      return ((aIndex - todayIndex + 7) % 7) - ((bIndex - todayIndex + 7) % 7);
    });

    const labels = sortedData?.map(item => item.day);
    
    const datasets = [
      {
        label: "Sales",
        data: sortedData?.map(item => parseFloat(item.sales)),
        backgroundColor: "rgba(255, 220, 199, 1)",
        barPercentage: 1,
        barThickness: 15,
        borderRadius: 4,
      },
      {
        label: "Profit",
        data: sortedData?.map(item => parseFloat(item.profits)),
        backgroundColor: "rgba(224, 111, 44, 1)",
        barPercentage: 0.5,
        borderRadius: 4,
      },
    ];

    const newChartData = { labels, datasets };

    setChartData(newChartData);
    setYAxisOptions(calculateYAxisOptions(newChartData));
  }, [weeklyData]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Weekly Data",
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false, // Remove vertical grid lines
        },
        barPercentage: 0.2, // Adjust bar width
        categoryPercentage: 0.9, // Adjust category width
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value >= 1000 ? value / 1000 + "k" : value;
          },
          stepSize: yAxisOptions.stepSize,
        },
        max: yAxisOptions.max,
      },
    },
  };

  if (!chartData && weeklyData) return (
    <div className="flex items-center justify-center h-[100%] w-[100%]">
      <Spinner color="#E06F2C"/>
    </div>
  )
  return <Bar data={chartData} options={options} className="w-[100%] h-[100%]"/>;
};

export default BarChart;
