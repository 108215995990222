import React, { useEffect, useState } from "react";
import DotDropdown from "../ProductDetails/DotDropdown";
import { getProductNames, convertToDate, formatDate } from "../../utils";
import walletSymbol from "../../assets/walletSymbol.svg";
import dollarSign from "../../assets/dollarSign.svg";

function OrdersTable({ orders, accountId, userId, token, onCancelOrder }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(orders);
  }, [orders]);

  const handleCancelOrder = (orderId) => {
    onCancelOrder(orderId);
    setData((prevData) =>
      prevData.filter((order) => order.orderId !== orderId)
    );
  };

  return (
    <table className="table">
      <colgroup>
        <col style={{ width: "4%" }} />
        <col style={{ width: "35%" }} />
        <col style={{ width: "1%" }} />
        <col style={{ width: "12%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "4%" }} />
      </colgroup>
      <tbody style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}>
        {data
          .sort((a, b) => new Date(b.placedAt.seconds) - new Date(a.placedAt.seconds))
          .map((item, index) => (
            <tr key={index}>
              <td style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}>
                {
                  item?.method === "Account" ? (
                    <img src={walletSymbol} alt="wallet" />
                  ): 
                  (
                    <img src={dollarSign} alt="dollar" />
                  )
                }
              </td>
              <td
                style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}
              >
                {getProductNames(item)}
              </td>
              <td
                style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}
              >
                {<div className="font-semibold ms-[70px]">{"Sale"}</div>}
              </td>
              <td
                className="ms-[-10px]"
                style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}
              >{`$${item.total}`}</td>
              <td
                className="ms-[30px]"
                style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}
              >
              
                
                {formatDate(
                  convertToDate(
                    (item?.placedAt).nanoseconds,
                    (item?.placedAt).seconds
                  )
                )}
              </td>

              <td
                style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}
              >
                <DotDropdown
                  goToAccount={false}
                  userId={userId}
                  accountId={accountId}
                  token={token}
                  onCancel={handleCancelOrder}
                  orderId={item?.orderId}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default OrdersTable;
