import React, { useState } from "react";

const FloatingInputField = ({ label, onChange, value, id, type }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative text-xs leading-[18px] font-normal">
      {isFocused && (
        <div className="absolute top-0 left-5 h-[1px] w-[90px] bg-white" />
      )}
      <input
        type={type || "text"}
        id={id}
        className="block px-[25px] focus:px-[24px] bg-[#E06F2C0F] h-[42px] w-full text-[14px] leading-[21px] text-[#000000] rounded-[10px] appearance-none focus:outline-none focus:border-[#E06F2C] focus:border peer"
        placeholder=""
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)} // when element looses focus
        value={value}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="absolute duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] text-[#1C1C1C] peer-placeholder-shown:text-[#1C1C1C] peer-focus:text-[#E06F2C] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-[31px]"
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingInputField;
