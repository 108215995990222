export const tabType = [
  { label: "Camper", value: "camper" },
  { label: "Staff", value: "staff" },
  { label: "Cost", value: "cost" },
];

export const itemsType = [
  { label: "Camper", value: "camper" },
  { label: "Staff", value: "staff" },
  { label: "Cost", value: "cost" },
];
