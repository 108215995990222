import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import SampleTable from "../Common/SampleTable";
import { sampleProductData } from "../../data/SampleTemplateData";
import { handleDownloadSample } from "../../utils";
import DownloadUploadButton from "../Common/DownloadUploadButton";

const BulkUploadContent = (props) => {
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [uploadedCategories, setUploadedCategories] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (!selectedFile.name.endsWith(".csv")) {
      toast.error("Please select a valid CSV file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result;
      const rows = csvData.split("\n");
      const jsonProducts = rows.slice(1).map((row) => {
        const values = row.split(",");
        return {
          productName: values[0],
          category: values[1],
          cost: parseFloat(values[2]?.replace("$", "")),
          price: parseFloat(values[3]?.replace("$", "")),
          stock: parseInt(values[4]),
        };
      });

      setProducts(jsonProducts);

      const uniqueCategories = new Set(
        jsonProducts.map((product) => product.category)
      );
      setUploadedCategories([...uniqueCategories]);
    };

    setFile(selectedFile);

    reader.readAsText(selectedFile);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const handleUpload = async () => {
    if (!file || products.length === 0) {
      toast.error("Please select a file to upload");
      return;
    }

    if (!props.categories || !Array.isArray(props.categories)) {
      toast.error("Failed to fetch categories");
      return;
    }

    const existingCategories = props.categories.map((category) => ({
      categoryName: category.categoryName.toLowerCase(),
      categoryId: category.categoryId,
    }));

    const missingCategories = uploadedCategories.filter(
      (category) =>
        !existingCategories.some(
          (c) => c.categoryName === category.toLowerCase()
        )
    );

    const invalidProducts = products.filter(
      (product) =>
        !existingCategories.some(
          (c) => c.categoryName === product.category.toLowerCase()
        )
    );

    if (invalidProducts.length > 0) {
      toast.error(`Invalid products found. Please check the categories.`);
      return;
    }

    const productWithCategoryIds = products.map((product) => {
      const category = existingCategories.find(
        (c) => c.categoryName === product.category.toLowerCase()
      );
      return {
        ...product,
        categoryId: category ? category.categoryId : null,
      };
    });

    try {
      toast.loading("Uploading products...");
      const payload = {
        products: productWithCategoryIds,
        userId: props.userId,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/bulkUpload`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      if (res && res.data && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.handleUpload(res.data.data);
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }

    props.handleClose();
  };

  return (
    <div className="flex flex-col px-4 py-3 gap-2 h-full mx-auto max-w-[926px]">
      <div className="flex justify-between items-center">
        <h5 className="text-[16px] font-bold leading-[24px] text-gray-900">
          Bulk Product Upload
        </h5>
      </div>

      <div className="flex flex-col items-center gap-2 text-center">
        <div className="flex flex-col">
          <h2 className="text-base font-medium text-[#12141E] leading-[26.16px]">
            How to Bulk Upload a CSV File to the Dashboard
          </h2>
          <p className="text-[14px] text-[#12141E]">
            To bulk upload, prepare your spreadsheet with the exact column
            headers (Product Name, Category, Cost, Price, Stock) and title
            sheet. Fill in the rows with your data, leaving blank cells if
            certain information is not available. Save the file in CSV format.
            Ensure the headers and sheet title match exactly for successful
            uploading.
          </p>
        </div>

        <SampleTable data={sampleProductData} />
      </div>

      <div className="flex items-center justify-center w-full my-[25px]">
        <DownloadUploadButton
          handleDownload={() =>
            handleDownloadSample(
              "/sampleTemplate/ProductSample.csv",
              "Product Sample.csv"
            )
          }
          title="Download"
          subTitle="Sample Template"
          file={file}
          handleFileChange={handleFileChange}
        />
      </div>

      <div className="flex justify-center gap-[11px]">
        <button
          className="px-4 py-2 bg-[#E0E0E0] text-black rounded-md"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-[#E06F2C] text-white rounded-md"
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default BulkUploadContent;
