import React, { useState, useEffect, useContext } from "react";
import DotDropdown from "../ProductDetails/DotDropdown";
import SortByList from "./SortByList";
import { SortByItems } from "../../data/allOrderTableData";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Tooltip } from "@chakra-ui/react";
import { AuthContext } from "../../auth/AuthProvider";
import { getOrderedItems, getProductNames } from "../../utils";
import { formatDate, convertToDate } from "../../utils";
import { Spinner } from "@chakra-ui/react";

function AllOrdersTable() {
  const [sortingType, setSortingType] = useState("Newest");
  const {
    user,
    token,
    orders,
    setOrders,
    currentOrdersPage,
    setCurrentOrdersPage,
    totalOrdersPages,
    totalOrders,
    ordersWithPagination,
    loading,
  } = useContext(AuthContext);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const ordersPerPage = 20;

  const currentOrders = filteredOrders;

  const paginate = async (pageNumber) => {
    setOrders([]);
    await ordersWithPagination(pageNumber, ordersPerPage);
    setCurrentOrdersPage(pageNumber);
  };

  const handleCancelOrder = (orderId) => {
    setOrders((prevData) =>
      prevData.filter((order) => order.orderId !== orderId)
    );
  };

  const headingStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    backgroundColor: "#f8f8f8",
    verticalAlign: "middle",
    position: "sticky",
    top: "0px",
  };

  const sortData = (type, data) => {
    if (type === "Camper" || type === "Cost" || type === "Staff") {
      return data.filter((item) => item.accountType === type);
    } else if (type === "Cash" || type === "Account") {
      return data.filter((item) => item.method === type);
    } else {
      const sortedData = [...data].sort((a, b) => {
        if (type === "Newest") {
          return (
            new Date(b.orderedTime.seconds) - new Date(a.orderedTime.seconds)
          );
        } else if (type === "Oldest") {
          return (
            new Date(a.orderedTime.seconds) - new Date(b.orderedTime.seconds)
          );
        }
        return 0;
      });

      return sortedData;
    }
  };

  const handleSortbyChange = (selectedType) => {
    setSortingType(selectedType);
  };

  useEffect(() => {
    if (filteredOrders) {
      setFilteredOrders(sortData(sortingType, orders));
    } else {
      setFilteredOrders(orders);
    }
  }, [sortingType, orders]);

  return (
    <div className="pb-3">
      <table className="table">
        <colgroup>
          <col style={{ width: "18%" }} />
          <col style={{ width: "11%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          <tr>
            <th style={{ ...headingStyle, borderTopLeftRadius: "10px" }}>
              Name
            </th>
            <th style={{ ...headingStyle }}>Type</th>
            <th style={{ ...headingStyle }}>Items</th>
            <th style={{ ...headingStyle }}>Price</th>
            <th style={{ ...headingStyle }}>Profits</th>
            <th style={{ ...headingStyle }}>Method</th>
            <th style={{ ...headingStyle }}>D&T</th>
            <th style={{ ...headingStyle, borderTopRightRadius: "10px" }}>
              {<SortByList items={SortByItems} onSelect={handleSortbyChange} />}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr style={{ border: "none" }}>
              <td
                colSpan="100%"
                style={{ height: "400px", border: "none" }}
                className="text-center align-middle"
              >
                <Spinner />
              </td>
            </tr>
          ) : (
            currentOrders.map((item, index) => (
              <tr key={index} style={{ height: "60px" }}>
                <td style={{ verticalAlign: "middle" }}>{item.accountName}</td>
                <td style={{ verticalAlign: "middle" }}>
                  <div className="font-normal">{item.accountType}</div>
                </td>
                <td className="ms-[-10px]" style={{ verticalAlign: "middle" }}>
                  <Tooltip
                    hasArrow
                    label={getProductNames(item)}
                    bg="black"
                    color="white"
                    placement="top"
                  >
                    <button
                      className="h-[29px] px-[10px]"
                      style={{
                        border: "1.5px solid rgba(234, 234, 234, 1)",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    >
                      {getOrderedItems(item)}
                    </button>
                  </Tooltip>
                </td>
                <td className="ms-[30px]" style={{ verticalAlign: "middle" }}>
                  {`$${item?.total}`}
                </td>
                <td
                  style={{ verticalAlign: "middle" }}
                >{`$${item?.profit}`}</td>
                <td style={{ verticalAlign: "middle" }}>{item?.method}</td>
                <td style={{ verticalAlign: "middle" }}>
                  {formatDate(
                    convertToDate(
                      (item?.orderedTime).nanoseconds,
                      (item?.orderedTime).seconds
                    )
                  )}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <span className="d-flex items-center ms-[30px]">
                    <DotDropdown
                      accountId={item?.accountId}
                      userId={user.id}
                      token={token}
                      orderId={item.orderId}
                      onCancel={handleCancelOrder}
                      goToAccount={true}
                    />
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {totalOrders > ordersPerPage && orders.length ? (
        <div className="pagination d-flex justify-content-center mt-4">
          <button
            onClick={() => paginate(currentOrdersPage - 1)}
            disabled={currentOrdersPage === 1}
            className="custom-button me-2"
          >
            Previous
          </button>
          <span className="align-self-center mx-2">
            Page {currentOrdersPage} of {totalOrdersPages}
          </span>
          <button
            onClick={() => paginate(currentOrdersPage + 1)}
            disabled={currentOrdersPage === totalOrdersPages}
            className="custom-button ms-2"
          >
            Next
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AllOrdersTable;
