import React from "react";
import Refresh from "./RefreshButton";

const InputModalContent = (props) => {
 
  return (
    <div className="flex flex-col">
      <div
        className="pl-[13px] font-normal mb-[5px]"
        style={{ color: "#909090", fontSize: "11px", lineHeight: "16.5px" }}
      >
        {props.name}
      </div>
      <div className="relative w-full h-[41px]">
        {props.leading && (
          <div
            className="absolute left-[16px] top-1/2 transform -translate-y-1/2"
            style={{ color: "#9C9C9C", fontSize: "16px" }}
          >
            &#36;
          </div>
        )}
        <input
          className={`w-full h-full px-[18px] focus:outline-none ${
            props.leading ? "pl-[32px]" : ""
          } ${props.trailing ? "pr-[32px]" : ""} `}
          style={{ borderRadius: "5px", background: "#FFFFFF" }}
          value={props.value}
          id={props.id}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {props.trailing && (
          <div
            className=" absolute right-[9px] top-1/2 transform -translate-y-1/2 h-[24px] w-[24px] flex justify-center items-center"
            style={{ backgroundColor: "#F8F8F8", borderRadius: "5px" }}
            onClick={props.onClick}
          >
            <Refresh check={true}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputModalContent;
