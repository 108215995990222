import Accounts from "../components/Accounts";
import AllOrders from "../components/allOrders/allOrders";
import GridLayout from "../components/GridLayout/GridLayout";
import Products from "../components/Products";
import Setting from "../components/Settings/Setting";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import AccountView from "../components/accountView/AccountView";
import Transactions from "../components/Transactions";
import GeneralSettings from "../components/Settings/rightLayout/generalSettings";
import BunksSetting from "../components/Settings/rightLayout/bunksSetting";
import LoginInfo from "../components/Settings/rightLayout/loginInfo";
import OnlinePaymentsSetting from "../components/Settings/rightLayout/OnlinePaymentsSetting";

export const routes = [
  {
    layout: "admin",
    pages: [
      {
        name: "Dashboard",
        path: "/dashboard",
        element: <GridLayout />,
      },
      {
        name: "Products",
        path: "/products",
        element: <Products />,
      },
      {
        name: "Orders",
        path: "/orders",
        element: <AllOrders />,
      },
      {
        name: "Accounts",
        path: "/accounts",
        element: <Accounts />,
      },
      {
        name: "Setting",
        path: "/setting/*",
        element: <Setting />,
        children: [
          {
            id: 0,
            name: "General Settings",
            path: "general-settings",
            element: <GeneralSettings />,
          },
          {
            id: 1,
            name: "Bunks",
            path: "bunks",
            element: <BunksSetting />,
          },
          {
            id: 2,
            name: "Login Info",
            path: "login-info",
            element: <LoginInfo />,
          },
          {
            id: 3,
            name: "Online Payments",
            path: "online-payments",
            element: <OnlinePaymentsSetting />,
          },
        ],
      },
      {
        name: "Transactions",
        path: "/transactions",
        element: <Transactions />,
      },
      {
        name: "Product Details",
        path: "/products/product-details",
        element: <ProductDetails />,
      },
      {
        name: "Account Details",
        path: "/accounts/account-details/:accountId",
        element: <AccountView />,
      },
    ],
  },
];
