import React, { useContext } from "react";
import { AuthContext } from "../../auth/AuthProvider";
import LifetimeDataChip from "../Common/LifetimeDataChip";

function LifeTime() {
  const { dashboardLifeTime, totalCashinCamper } = useContext(AuthContext);
  return (
    <div className="flex h-100 w-100 px-2 md:px-1 ">
      <div className="flex flex-column px-4 pt-3 gap-1">
        <div
          className="w-[81px] h-[32px] "
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "32px",
          }}
        >
          Lifetime
        </div>

        <LifetimeDataChip
          name="Total Sales"
          data={parseFloat(dashboardLifeTime?.totalSales || 0)?.toFixed(2)}
        />
        <LifetimeDataChip
          name="Total Profit"
          data={parseFloat(dashboardLifeTime?.totalProfits || 0)?.toFixed(2)}
        />
        <LifetimeDataChip
          name="Product Sold"
          data={dashboardLifeTime?.productSold || 0}
        />
        <LifetimeDataChip name="Cash in Camper Accounts" data={parseFloat(totalCashinCamper || 0).toFixed(2)} />
      </div>
    </div>
  );
}

export default LifeTime;
