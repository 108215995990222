import React, { useContext } from "react";
import DotDropdown from "./DotDropdown";
import { capitalize, getAccountNameByID, getAccountTypeByID } from "../../utils";
import { AuthContext } from "../../auth/AuthProvider";
import { convertToDate, formatDate } from "../../utils";

const RecentOrdersTable = ({ orders = [], accounts = [], setOrders }) => {
  const { user, token } = useContext(AuthContext);

  const headingStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    backgroundColor: "#f8f8f8",
    verticalAlign: "middle",
    position: "sticky",
    top: "0px",
  };

  const handleCancelOrder = (orderId) => {
    setOrders((prevData) =>
      prevData.filter((order) => order.orderId !== orderId)
    );
  };

  return (
    <table className="table">
      <colgroup>
        <col style={{ width: "8%" }} />
        <col style={{ width: "4%" }} />
        <col style={{ width: "4%" }} />
        <col style={{ width: "4%" }} />
        <col style={{ width: "4%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "2%" }} />
      </colgroup>
      <thead>
        <tr>
          <th style={{ ...headingStyle, borderTopLeftRadius: "10px" }}>Name</th>
          <th style={{ ...headingStyle }}>Type</th>
          <th style={{ ...headingStyle }}>Quantity</th>
          <th style={{ ...headingStyle }}>Sales</th>
          <th style={{ ...headingStyle }}>Method</th>
          <th style={{ ...headingStyle }}>D&T</th>
          <th style={{ ...headingStyle, borderTopRightRadius: "10px" }}>.</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(orders) && orders.length > 0 ? (
          orders.map((item, index) => (
            <tr key={index} style={{ maxHeight: '70px' }}>
              <td style={{ verticalAlign: "middle" }}>
                {capitalize(getAccountNameByID(item.accountId, accounts)) || "Cash"}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <div className="font-semibold">
                  {getAccountTypeByID(item.accountId, accounts) || "--"}
                </div>
              </td>
              <td className="ms-[-10px]" style={{ verticalAlign: "middle" }}>
                <button
                  className="h-[29px] px-[10px]"
                  style={{
                    border: "1.5px solid rgba(234, 234, 234, 1)",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  {item.qty}
                </button>
              </td>
              <td className="ms-[30px]" style={{ verticalAlign: "middle" }}>
                {`$${(item.price * item.qty).toFixed(2)}`}
              </td>

              <td style={{ verticalAlign: "middle" }}>{item?.method}</td>
              <td style={{ verticalAlign: "middle" }}>
                {" "}
                {formatDate(
                  convertToDate(
                    (item?.orderedTime).nanoseconds,
                    (item?.orderedTime).seconds
                  )
                )}
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <span className="d-flex items-center ms-[30px]">
                  <DotDropdown
                    accountId={item?.accountId}
                    goToAccount={true}
                    token={token}
                    userId={user.id}
                    orderId={item?.orderId}
                    onCancel={handleCancelOrder}
                    cancelAndRefund={false}
                  />
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" style={{ textAlign: "center" }}>
              No recent orders
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RecentOrdersTable;
