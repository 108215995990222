import TripleDot from "../../assets/TripleDot.svg";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem, Box } from "@chakra-ui/react";
import DeleteContent from "../Common/deleteContent";
import Modal from "../Common/modal";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

function DotDropdown({
  userId,
  accountId,
  orderId,
  token,
  onCancel,
  goToAccount,
  cancelAndRefund = true,
}) {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/accounts/account-details/${accountId}`);
  };

  const handleCancelOrder = async () => {
    try {
      setIsCancelLoading(true);
      toast.loading("Cancelling order...");
      const res = await axios.delete(
        `${process.env.REACT_APP_HOST_URL}/api/pos/order/cancelOrder`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          data: { userId, orderId },
        }
      );
      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        onCancel(orderId);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setIsCancelLoading(false);
      toggleCancelModal();
    }
  };

  const isCashAccount = accountId.startsWith("CASH");

  return (
    <>
      <Menu>
        <MenuButton>
          <button className="d-flex justify-center items-center w-[26px] h-[26px]">
            <img src={TripleDot} alt="..." />
          </button>
        </MenuButton>
        <MenuList bg="#f8f8f8">
          {goToAccount && !isCashAccount && (
            <>
              <MenuItem
                bg="#f8f8f8"
                sx={{
                  _hover: {
                    backgroundColor: "gray",
                    borderRadius: "8px",
                  },
                }}
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#12141E",
                }}
                onClick={handleNavigate}
              >
                Go to Account
              </MenuItem>
              <Box
                as="hr"
                border="0.4px solid #D7D7D7"
                my={1}
                style={{ width: "90%", marginLeft: "5%" }}
              />
            </>
          )}
          {cancelAndRefund && (
            <MenuItem
              sx={{
                _hover: {
                  backgroundColor: "gray",
                  borderRadius: "8px",
                },
              }}
              bg="#f8f8f8"
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#12141E",
              }}
              onClick={toggleCancelModal}
            >
              Cancel order & Refund account
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Modal
        isVisible={isCancelModalOpen}
        onClose={toggleCancelModal}
        backgroundColor={"white"}
      >
        <DeleteContent
          handleClose={toggleCancelModal}
          handleDelete={handleCancelOrder}
          delete="false"
          isLoading={isCancelLoading}
        />
      </Modal>
    </>
  );
}

export default DotDropdown;
