import React from "react";
import downloadIcon from "../../assets/download.svg";
import uploadIcon from "../../assets/upload.svg";

const DownloadUploadButton = ({
  handleDownload,
  title,
  subTitle,
  file,
  handleFileChange,
}) => {
  return (
    <div className="flex flex-row items-center gap-[15px]">
      <button
        className="flex items-center h-[62px] gap-[10px] px-[16px] py-[8px] border-[2px] border-[#E0E0E0] rounded-[8px] text-[#12141E] bg-white"
        onClick={handleDownload}
      >
        <img
          src={downloadIcon}
          alt="icon"
          style={{ width: "24px", height: "24px" }}
        />
        <div className="flex flex-col text-left">
          <span className="text-[13px] leading-[18px] font-medium">
            {title}
          </span>
          <span className="text-[7px] sm:text-xs font-light leading-[19px]">
            {subTitle}
          </span>
        </div>
      </button>

      <label
        htmlFor="file-upload"
        className="flex items-center h-[62px] gap-[10px] px-[16px] py-[8px] border-[2px] border-[#E06F2C] rounded-[8px] text-[#12141E] bg-white cursor-pointer"
      >
        <img
          src={uploadIcon}
          alt="icon"
          style={{ width: "24px", height: "24px" }}
        />
        <div className="flex flex-col text-left">
          <span className="text-[13px] leading-[18px] font-medium">
            {file ? "File Selected" : "Choose File"}
          </span>
          <span className="text-[7px] sm:text-xs font-light leading-[19px]">
            {file ? file.name : "No file chosen"}
          </span>
        </div>
      </label>
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        className="hidden"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default DownloadUploadButton;
