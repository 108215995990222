import React, { useState } from "react";
import Logo from "../components/Common/Logo";
import sort from "../assets/Sort.png";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import LeftLayout from "./LeftLayout";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isDrawerEnabled = useBreakpointValue({ base: true, md: false });

  const onClose = () => setIsOpen(false);

  return (
    <nav
      className={`w-full h-[62px] fixed top-0 z-50`}
      style={{
        background: "#1F2022",
        boxShadow: "0px 14px 34px 0px #00000040",
      }}
    >
      <div className="flex justify-center lg:justify-between items-center h-full  mx-auto px-6 lg:px-[100px] relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="lg:hidden text-white focus:outline-none h-8 w-8 hover:bg-gray-100 rounded-md flex items-center justify-center absolute left-[23px]"
        >
          <img src={sort} alt="sort" className="w-[24px] h-[24px]" />
        </button>

        <Logo />
      </div>

      {isDrawerEnabled && (
        <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent width="100px" maxWidth="100px">
            <LeftLayout onClose={onClose} />
          </DrawerContent>
        </Drawer>
      )}
    </nav>
  );
};

export default Header;
