import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from "react-icons/fa";

const CustomSelect = ({
  options = [],
  defaultValue = "",
  onChange = () => {},
  className = "",
  dialogClass = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div 
      className={`relative w-full max-w-xs ${className}`}
      ref={dropdownRef}
    >
      
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full px-3 py-1 text-left text-[#00000066] bg-[#F8F8F8] rounded  cursor-pointer focus:outline-none ${className}`}
      >
        <div className="flex items-center justify-between gap-2">
                
            <div className="text-sm font-medium">
              {selectedOption || "choose"}
            </div>
          
          <FaChevronDown
            className={`w-4 h-4 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
          />
        </div>
      </button>

    
      {isOpen && (
        <div className="absolute z-10 w-full  bg-[#F8F8F8]  rounded-b ">
          <div className="py-1 border-t overflow-auto max-h-60">
            {options.map((option, index) => (
                <div>
                
              <div
                key={index}
                className={`px-3 py-2 text-sm text-[#00000066] cursor-pointer hover:bg-gray-700 ${
                  selectedOption === option ? 'bg-[#E06F2C] text-white rounded' : 'text-gray-400 rounded'
                }`}
                onClick={() => handleSelect(option)}
              >
                {option}
                
              </div>
             {
                !(index === options.length - 1 )&& (
                  <div className='border w-[80%] mx-auto'/>
                )
 
             }
            
              </div>
              
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;