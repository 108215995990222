import React from "react";
import tick from "../../assets/Tick.png";

const CircleSelection = (props) => {
  return (
    <div
      className={`rounded-full border transition-all duration-300 flex items-center justify-center cursor-pointer ${
        props.isSelected ? "bg-[#E06F2C]" : " border-[#CDCBCA] "
      }`}
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
      }}
      onClick={props.onClick}
    >
      {props.isSelected && <img src={tick} alt="Correct" />}
    </div>
  );
};

export default CircleSelection;
