import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DoughnutChart = ({ dashboardData }) => {
  const chartRef = useRef();

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const data = {
      datasets: [
        {
          data: [
            dashboardData?.camperOrders,
            dashboardData?.costOrders,
            dashboardData?.staffOrders,
            dashboardData?.cashOrders,
          ],
          backgroundColor: [
            "rgba(224, 111, 44, 1)",
            "rgba(255, 163, 108, 1)",
            "rgba(255, 220, 199, 1)",
            "rgba(188, 77, 12, 1)",
          ],
          borderColor: [
            "rgba(224, 111, 44, 1)",
            "rgba(255, 163, 108, 1)",
            "rgba(255, 220, 199, 1)",
            "rgba(188, 77, 12, 1)",
          ],
          borderWidth: 15,
          borderRadius: 20,
        },
      ],
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        cutout: "90%",
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                const labels = ["Camper", "Cost", "Staff", "Cash"];
                let label = labels[context.dataIndex] || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed !== null) {
                  label += context.parsed;
                }
                return label;
              },
            },
            displayColors: false, // This will remove the color box
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: customize tooltip background
            bodyColor: "white", // Optional: customize tooltip text color
            padding: 10, // Optional: adjust padding
            bodyFont: {
              // Optional: customize font
              family: "Poppins, sans-serif",
              size: 14,
            },
          },
        },
      },
      plugins: [
        {
          id: "custom-text",
          afterDraw: (chart) => {
            const { ctx, width, height } = chart;
            ctx.save();
            const fontSize = (height / 90).toFixed(2);
            ctx.font = `600 ${fontSize}em poppins, sans-serif`;
            ctx.textBaseline = "middle";
            ctx.fillStyle = "black"; // You can change the color here

            const text = dashboardData?.totalOrders || 0;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.restore();
          },
        },
      ],
    };

    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, [dashboardData?.totalOrders]);

  return (
    <div style={{ position: "relative", width: "232px", height: "232px" }}>
      <canvas
        ref={chartRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <div
        className="absolute"
        style={{
          top: "59%",
          left: "42%",
          color: "#CDCBCA",
          fontFamily: "nunito",
          fontWeight: "400",
          fontSize: "14px",
        }}
      >
        Orders
      </div>
    </div>
  );
};

export default DoughnutChart;
