import React from "react";

const Modal = ({ isVisible, onClose, children, backgroundColor }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      onClick={onClose}
    >
      <div className="max-h-[100vh] overflow-y-auto hide-scrollbar">
        <div
          className="p-2 max-w-[90%] mx-auto mt-[10%] mb-[10%]"
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#F8F8F8",
            borderRadius: "18px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
