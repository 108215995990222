import React from 'react'
import AllOrdersTable from './allOrderTable'

function AllOrders() {
  return (
    <div className='h-[100%] w-100 bg-white px-[55px] pt-[35px]' style={{borderRadius: "20px"
    }} >
        <div className='d-flex flex-column h-100 w-100 '>
            <div className='font-bold text-5xl'>Orders</div>
            <div className='h-[96%] mt-[30px] overflow-y-auto custom-scrollbar'>
                <AllOrdersTable />
            </div>
        </div>
    </div>
  )
}

export default AllOrders