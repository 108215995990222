import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import downAngle from "../../assets/downAngle.svg";

function SortByList({ items, onSelect }) {
  const [selectedItem, setSelectedItem] = useState("Newest");
  const toggleRef = useRef();

  const handleToggle = () => {
    toggleRef.current.click();
  };

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(18, 20, 30, 1)",
  };

  const handleSelect = (newTitle) => {
    setSelectedItem(newTitle);
    onSelect(newTitle);
  };

  return (
    <Dropdown className="custom-dropdown object-cover">
      <div className="d-flex items-center" onClick={handleToggle}>
        <div
          style={{
            fontWeight: "600",
            fontSize: "16.5px",
            backgroundColor: "#f8f8f8",
          }}
        >
          {selectedItem}
        </div>
        <div className="d-flex items-end w-[12px] h-[10px] ms-[5px]">
          <img src={downAngle} alt="Down" />
        </div>

        <Dropdown.Toggle
          id="dropdown-basic"
          ref={toggleRef}
          style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
            display: "flex",
            alignItems: "center",

          }}
        ></Dropdown.Toggle>
      </div>

      <Dropdown.Menu
        style={{ backgroundColor: "rgba(248, 248, 248, 1)", border: "none" }}
      >
        {items.map((item, index) => (
          <React.Fragment key={item.value}>
            <Dropdown.Item
              style={{
                ...normalStyle,
                backgroundColor: `${
                  selectedItem === item.value ? "rgba(224, 111, 44, 1)" : ""
                }`,
                borderRadius: selectedItem === item.value ? "13px" : "0",
                color: selectedItem === item.value ? "#f8f8f8" : "",
              }}
              onClick={() => handleSelect(item.value)}
            >
              {item.label}
            </Dropdown.Item>
            {index < items.length - 1 && (
              <hr
                style={{
                  width: "80%",
                  margin: "5px auto", // Adjust margin as needed
                  border: "none",
                  borderBottom: "2px solid #D7D7D7",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SortByList;
