import React from "react";
import { routes } from "../../routes";
import { Route, Routes } from "react-router-dom";
import SettingLeftLayout from "./SettingLeftLayout";

function Setting() {
  const settingRoute = routes[0].pages.find((page) => page.name === "Setting");
  const settingsChildren = settingRoute?.children || [];

  return (
    <div className="flex flex-row w-full h-full gap-6 overflow-auto">
      <SettingLeftLayout settingsChildren={settingsChildren} />

      <div className="flex flex-grow pt-[2rem] py-[2rem] px-[2rem] h-[100%] w-full rounded-lg bg-white min-w-[730px] overflow-y-auto">
        <Routes>
          {settingsChildren.map((route) => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Routes>
      </div>
    </div>
  );
}

export default Setting;
