import React from 'react'

function LifetimeDataChip({name , data}) {
  return (
    <div className="d-flex flex-column ">
    <div
      className="d-flex items-center"
      style={{
        fontFamily: "Open Sans",
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "32px",
        color: "rgba(0, 0, 0, 0.3)",
      }}
    >
      {name}
    </div>
    <div
      className="w-[100%] mt-[-5px] text-[36px] md:text-[24px] lg:text-[30px] 2xl:text-[37px]"
      style={{    
        color: "rgba(224, 111, 44, 1)",
      }}
    >
      &#36;
      {/* {parseFloat(dashboardLifeTime?.totalSales || 0)?.toFixed(2)} */}
      {data}
    </div>
  </div>
  )
}

export default LifetimeDataChip