import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cross from "../../../assets/Cross.svg";

function AddBalance(props) {
  const [method, setMethod] = useState("");
  const [AddedBalance, setAddedBalance] = useState(0);

  const handleSwitch = () => {
    props.handleClose();
    props.handleShowRemove();
  };

  const handleAddedBalance = () => {
    props.handleAddBalance(AddedBalance, method);
    setAddedBalance(0);
    setMethod("");
    props.handleClose();
  };

  const isAddDisabled = () => {
    return AddedBalance === 0 || method.trim() === "";
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="balance-custom-modal"
      >
        <div className="d-flex flex-col w-[420px] h-[270px]">
          <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
            <div
              style={{
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "31.5px",
                marginLeft: "33px",
              }}
            >
              Add Balances
            </div>
            <button
              className="w-[15.5px] h-[15.5px] me-[23px]"
              onClick={props.handleClose}
            >
              <img src={Cross} alt="cross" />
            </button>
          </div>
          <div
            className="h-[20px]"
            style={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "19.5px",
              marginLeft: "33px",
              color: "rgba(0, 0, 0, 0.28)",
            }}
          >
            {props.name}
          </div>
          <div className="d-flex flex-col justify-center items-center w-100 gap-[12px] mt-[20px]">
            <div
              className="d-flex items-center justify-center w-[250px] h-[40px] "
              style={{ background: "#f8f8f8", borderRadius: "9px" }}
            >
              <div className="ms-[15px]">$</div>
              <input
                placeholder="Amount"
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  backgroundColor: "#f8f8f8",
                  paddingLeft: "10px",
                }}
                className="custom-placeholder"
                onChange={(e) => setAddedBalance(e.target.value)}
                type="number"
              />
            </div>
            <div className="floating-input-group h-[40px] w-[250px]">
              <input
                type="text"
                className="floating-input"
                onChange={(e) => setMethod(e.target.value)}
              />
              <label
                className={`floating-placeholder ${
                  method.length === 0 ? "" : "floating-fill"
                }`}
              >
                Method
              </label>
            </div>
          </div>

          <div className="d-flex justify-center items-center h-[30px] mt-[15px]">
            <button
              className="h-[30px] w-[94px]"
              style={{
                backgroundColor: "#FF8943",
                borderRadius: "5px",
                color: "#fff",
                cursor: isAddDisabled() ? "not-allowed" : "pointer",
                opacity: isAddDisabled() ? 0.5 : 1,
              }}
              onClick={() => handleAddedBalance()}
              disabled={isAddDisabled()}
            >
              Add
            </button>
          </div>
          <div className="d-flex justify-center h-[20px] mt-[9px]">
            <button
              onClick={handleSwitch}
              className="h-[20px]"
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "rgba(0, 0, 0, 0.28)",
              }}
            >
              Remove Cash
            </button>
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .balance-custom-modal .modal {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .balance-custom-modal .modal-dialog {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 420px;
          height: 300px;
        }

        .balance-custom-modal .modal-content {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px !important;
          height: 280px;
        }

        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
    </>
  );
}

export default AddBalance;
