import React from "react";
import { CiSearch } from "react-icons/ci";
import { Input } from "@chakra-ui/react";

const SearchBar = ({ searchIcon, placeholder, className ,onChange, value}) => {
  return (
    <div
      className={`d-flex align-items-center ${className} h-[40px]`}
      style={{ borderRadius: "6px", backgroundColor: "#F8F8F8" }}
    >
      {searchIcon === "false" ? null : (
        <div className="h-[16px] ms-3">
          <CiSearch width="16px" height="16px" color="#7D7D7D" />
        </div>
      )}
      <div className="d-flex align-items-center mx-2" style={{ width: "100%" }}>
        <Input
          placeholder={placeholder}
          className="placeholder-custom-gray"
          variant="unstyled"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
