import React from "react";

const BunkBox = (props) => {
  const { isSelected, bunk, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className="inline-block h-[24px] px-[12px] justify-center items-center hover:cursor-pointer"
      style={{
        borderRadius: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: isSelected ? "#E06F2C" : "#DDDDDD",
        textAlign: "center",
        display: "flex",
      }}
      onClick={handleClick}
    >
      <div
        className="flex items-center justify-center leading-[13.5px] font-semibold"
        style={{ color: isSelected ? "#E06F2C" : "#939393", fontSize: "9px" }}
      >
        {bunk}
      </div>
    </div>
  );
};

export default BunkBox;
