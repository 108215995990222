import React from "react";
import deposit from "../../assets/deposit.svg";
import withdrawl from "../../assets/withdrawl.svg";

function BalanceHistoryTable({ history }) {
  const sortedHistory = history.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  return (
    <table className="table">
      <colgroup>
        <col style={{ width: "4%" }} />
        <col style={{ width: "4%" }} />
        <col style={{ width: "4%" }} />
      </colgroup>
      <tbody style={{ backgroundColor: "#f8f8f8", verticalAlign: "middle" }}>
        {sortedHistory.map((item, index) => (
          <tr key={index}>
            <td style={{ width: "80px", backgroundColor: "#f8f8f8" }}>
              {item.type === "deposit" ? (
                <div className="w-[24px] h-[24px]">
                  <img src={deposit} alt="deposit" />
                </div>
              ) : (
                <div className="w-[24px] h-[24px]">
                  <img src={withdrawl} alt="withdrawal" />
                </div>
              )}
            </td>
            <td
              className="text-mini"
              style={{
                width: "50%",
                backgroundColor: "#f8f8f8",
                verticalAlign: "middle",
              }}
            >
              {<div className="w-[80px] ms-[-8px]">{item.method}</div>}
            </td>
            <td
              className="text-mini font-medium"
              style={{
                width: "25%",
                backgroundColor: "#f8f8f8",
                verticalAlign: "middle",
              }}
            >
              {`${item.type === "deposit" ? "+" : "-"}`}
              &#36;{item.amount}
            </td>
            <td
              className="text-xs"
              style={{
                width: "25%",
                backgroundColor: "#f8f8f8",
                verticalAlign: "middle",
              }}
            >
              {new Date(item.timestamp).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default BalanceHistoryTable;
