import React, { useState, useEffect } from "react";
import CustomSelect from "./customDropdown";

function AddOnInput({ id, addOn, onDelete, onUpdate }) {
  const dropdownOptions = ["Price", "Free", "Delete"];
  const [showInput, setShowInput] = useState(addOn.price || false);
  const [addonData, setAddOnData] = useState({
    name: addOn.name || "",
    price: addOn.price || 0,
  });

  useEffect(() => {
    onUpdate(id, addonData);
  }, [addonData]);

  const handleOptionChange = (option) => {
    if (option === "Delete") {
      onDelete(id);
    } else if (option === "Free") {
      setAddOnData((prev) => ({ ...prev, price: 0 }));
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  };

  const handleNameChange = (e) => {
    setAddOnData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handlePriceChange = (e) => {
    // Allow decimal values, default to 0 if invalid
   
    setAddOnData((prev) => ({ ...prev, price: e.target.value }));
  };

  return (
    <div className="d-flex ml-2 gap-4 items-center w-100 h-[40px] mt-2">
      <div
        className="d-flex items-center justify-center h-[40px]"
        style={{ background: "#f8f8f8", borderRadius: "9px" }}
      >
        <input
          placeholder="Title"
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "#f8f8f8",
            paddingLeft: "20px",
          }}
          className="custom-placeholder"
          value={addonData.name}
          onChange={handleNameChange}
        />
      </div>
      <div className="flex items-center">
        <CustomSelect
          className="py-2"
          options={dropdownOptions}
          onChange={handleOptionChange}
          defaultValue={addonData.price ? "price" : "free"}
        />
        {showInput && (
          <div className="flex bg-[#f8f8f8] py-2 border-l px-2 rounded-r">
            <div>$</div>
            <input
              type="text"
              className="bg-[#f8f8f8] w-16 focus:outline-none"
              placeholder="0.00"
              value={addonData.price}
              onChange={handlePriceChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddOnInput;