import React, { useState, useContext, useEffect } from "react";
import backArrow from "../../assets/backArrow.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { AuthContext } from "../../auth/AuthProvider";
import CustomSelect from "../Common/customDropdown";
import ThirtyDayBarChart from "./ThirtyDayBarChart";

function MonthView({ onBack }) {
  const { monthlySalesAndProfit, fetchMonthlySalesAndProfit } = useContext(AuthContext);
  console.log("dailyMonthlySalesAndProfit", monthlySalesAndProfit);
  const date = new Date();
  const [currentDate, setCurrentDate] = useState({
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  });

  useEffect(() => {
    fetchMonthlySalesAndProfit(currentDate.month , currentDate.year);
  }, [currentDate]);

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate.year, prevDate.month - 2, 1);
      return { 
        month: newDate.getMonth() + 1, 
        year: newDate.getFullYear() 
      };
    });
  };
  
  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate.year, prevDate.month, 1);
      return { 
        month: newDate.getMonth() + 1, 
        year: newDate.getFullYear() 
      };
    });
  };

  const generateYearOptions = () => {
    const currentYear = currentDate.year;
    const years = [];
    for (let year = currentYear; year >= 2024; year--) {
      years.push(year.toString());
    }
    return years;
  };

  // const formattedMonth = currentDate.month.toLocaleString("default", {
  //   month: "long",
  // });

  const formattedMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date(currentDate.year, currentDate.month - 1, 1));

  return (
    <div className="flex flex-col bg-white h-full w-full rounded-lg px-16 py-8 ">
      <button className="flex gap-2 items-center  " onClick={onBack}>
        <img src={backArrow} alt="Back Arrow" className="w-3 h-3" />
        <div className="text-[14px] font-[500]">Back</div>
      </button>

      <div className="mt-10 ml-4">
        <div className=" font-semibold text-[20px]">Sales & Profits</div>
        <div
          style={{
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "21.82px",
            textAlign: "left",
            color: "#CDCBCA",
          }}
        >
          30 Days
        </div>
      </div>

      <div className="flex items-center justify-between  -mt-3">
        <div></div>
        <div className="flex items-center justify-center gap-6">
          <FaAngleLeft
            size={24}
            color="#7B91B0"
            onClick={handlePreviousMonth}
          />
          <div className="w-24 flex justify-center font-semibold text-[20px] text-[#E06F2C]">
            {formattedMonth}{" "}
          </div>
          <FaAngleRight size={24} color="#7B91B0" onClick={handleNextMonth} />
        </div>
        <div className="">
          <CustomSelect options={generateYearOptions()} defaultValue={currentDate.year}/>
        </div>
      </div>

      <div className=" w-full h-full mt-4">
        <ThirtyDayBarChart data={monthlySalesAndProfit}/>
      </div>
    </div>
  );
}

export default MonthView;
