import React from "react";
import { Link } from "react-router-dom";

function SettingOptionChip({ name, isSelected, path }) {
  return (
    <Link
      to={path}
      className={`
        px-3
        py-2 
        rounded
        transition-all
        duration-200
        relative
        ${isSelected ? "bg-[#FFF7F2]" : "hover:bg-gray-50"}
      `}
    >
      <div
        className={`
        absolute 
        left-0 
        top-0 
        bottom-0 
        w-1.5
        rounded-l
        ${isSelected ? "bg-[#E06F2C]" : ""}
      `}
      />
      <span
        className={`
        ${isSelected ? "text-[#000000] font-medium" : "text-[#535353]"}
      `}
      >
        {name}
      </span>
    </Link>
  );
}

export default SettingOptionChip;
