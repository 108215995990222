import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/campos.png";

const Logo = () => {
  return (
    <Link to={"/dashboard"}>
      <img src={logo} alt="logo" className="w-[192px] h-[62px]" />
    </Link>
  );
};

export default Logo;
