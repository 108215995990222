import React, { useContext, useEffect, useState } from "react";
import CheckboxComponent from "./checkboxComponent";
import BunkBox from "./bunkBox";
import SelectableGrid from "../../Common/selectableGrid";
import { AuthContext } from "../../../auth/AuthProvider";

const FilterModalContent = (props) => {
  const [selectedBunks, setSelectedBunks] = useState([]);
  const { user } = useContext(AuthContext);
  const [selectedAccountTypes, setselectedAccountTypes] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const isAnyFilterApplied = selectedBunks.length > 0 || selectedAccountTypes.length > 0 || sortBy !== null;

  const formattedBunks = user.bunks?.map((bunk) => {
    return { label: bunk.bunkNumber, value: bunk.bunkNumber };
  });

  const bunkItems = formattedBunks || [];

  useEffect(() => {
    if (props.appliedFilters?.length > 0) {
      setSelectedBunks(props.appliedFilters[2]);
      setselectedAccountTypes(props.appliedFilters[0]);
      setSortBy(props.appliedFilters[1]);
    }
  }, [props.appliedFilters]);

  const handleBunkSelect = (bunk) => {
    setSelectedBunks((prevSelected) => {
      let newSelectedBunks;
      if (bunk === "All Campers") {
        newSelectedBunks = prevSelected?.length === bunkItems?.length ? [] : bunkItems.map((item) => item.label);
      } else {
        newSelectedBunks = prevSelected.includes(bunk)
          ? prevSelected.filter((selected) => selected !== bunk)
          : [...prevSelected, bunk];
      }

      if(newSelectedBunks.length > 0 && !selectedAccountTypes.includes("Camper")) {
        setselectedAccountTypes(prev => [...prev, "Camper"]);
      }

      return newSelectedBunks;
    });
  };

  const clearFilters = () => {
    setSelectedBunks([]);
    setselectedAccountTypes([]);
    setSortBy(null);
  };

  const appliedFilters = () => {
    props.setAppliedFilters([selectedAccountTypes, sortBy, selectedBunks]);
    props.onApply();
  };
  const handleCheckboxChange = (name, isChecked) => {
    if (isChecked) {
      if (selectedAccountTypes?.length < 3) {
        if (name === "Camper" || name === "Cost" || name === "Staff") {
          setselectedAccountTypes([...selectedAccountTypes, name]);
        }
      }

      if (name === "Highest Bill" || name === "A - Z" || name === "Z- A") {
        setSortBy(name);
      }
    } else {
      setselectedAccountTypes(
        selectedAccountTypes.filter((item) => item !== name)
      );

      if (sortBy === name) {
        setSortBy(null);
      }
    }
  };

  const isAllCampersSelected = selectedBunks?.length === bunkItems?.length;

  return (
    <div className="flex flex-col items-center justify-center gap-[20px] p-[20px]">
      <div
        className="font-medium text-[14px] leading-[21px]"
        style={{ color: "#4A4A4A" }}
      >
        Filters
      </div>

      <div className="flex flex-row gap-[21px] ">
        <div className="flex flex-col gap-[4px]">
          <CheckboxComponent
            name="Camper"
            checked={selectedAccountTypes.includes("Camper") || selectedBunks.length > 0}
            onChange={handleCheckboxChange}
            disabled={selectedBunks.length > 0}
          />
          <hr style={{ color: "#D7D7D7", height: "0.4px", margin: "0px" }} />
          <CheckboxComponent
            name="Staff"
            checked={selectedAccountTypes.includes("Staff")}
            onChange={handleCheckboxChange}
          />
          <hr style={{ color: "#D7D7D7", height: "0.4px", margin: "0px" }} />
          <CheckboxComponent
            name="Cost"
            checked={selectedAccountTypes.includes("Cost")}
            onChange={handleCheckboxChange}
          />
          <hr style={{ color: "#D7D7D7", height: "0.4px", margin: "0px" }} />
          <CheckboxComponent
            name="Highest Bill"
            checked={sortBy === "Highest Bill"}
            onChange={handleCheckboxChange}
            disabled={sortBy !== null && sortBy !== "Highest Bill"}
          />
          <hr style={{ color: "#D7D7D7", height: "0.4px", margin: "0px" }} />
          <CheckboxComponent
            name="A - Z"
            checked={sortBy === "A - Z"}
            onChange={handleCheckboxChange}
            disabled={sortBy !== null && sortBy !== "A - Z"}
          />
          <hr style={{ color: "#D7D7D7", height: "0.4px", margin: "0px" }} />
          <CheckboxComponent
            name="Z- A"
            checked={sortBy === "Z- A"}
            onChange={handleCheckboxChange}
            disabled={sortBy !== null && sortBy !== "Z- A"}
          />
        </div>

        <div
          style={{
            width: "0.4px",
            height: "200px",
            backgroundColor: "#D7D7D7",
          }}
        />

        <div className="flex flex-col gap-[5px]">
          <SelectableGrid cols="6" items={bunkItems}>
            {({ onClick, isSelected, item }) => (
              <BunkBox
                onClick={() => handleBunkSelect(item.label)}
                isSelected={selectedBunks.includes(item.label)}
                bunk={item.label}
              />
            )}
          </SelectableGrid>
          <div className="mt-[7px]">
            <BunkBox
              onClick={() => handleBunkSelect("All Campers")}
              isSelected={isAllCampersSelected}
              bunk="All Campers"
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-center items-center gap-[11px] mt-3">
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: isAnyFilterApplied ? "#E0E0E0" : "#F0F0F0",
            borderRadius: "5px",
            color: isAnyFilterApplied ? "#000000" : "#A0A0A0",
            cursor: isAnyFilterApplied ? "pointer" : "not-allowed",
          }}
          onClick={clearFilters}
          disabled={!isAnyFilterApplied}
        >
          Clear All
        </button>
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: isAnyFilterApplied ? "#E06F2C" : "#F0A080",
            borderRadius: "5px",
            color: "#fff",
            cursor: isAnyFilterApplied ? "pointer" : "not-allowed",
          }}
          onClick={appliedFilters}
          disabled={!isAnyFilterApplied}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterModalContent;
