import React, { useContext } from "react";
import ProgressBar from "./ProgessBar";
import { AuthContext } from "../../auth/AuthProvider";
import { Spinner } from "@chakra-ui/react";

const TableComp = () => {
  const { dashboardTopProducts, loading } = useContext(AuthContext);
  const columns = ["#", "Name", "Popularity", "Sold"];

  const headingStyle = {
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
    textAlign: "left",
  };

  const itemStyle = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
  };

  const tdStyle = {
    color: "rgba(68, 74, 109, 1)",
    verticalAlign: "middle",
  };

  if (loading) {
    return (
      <div className="w-100 h-100 d-flex justify-center items-center">
        <Spinner color="#E06F2C" />
      </div>
    );
  }

  if (!dashboardTopProducts || dashboardTopProducts.length === 0) {
    return (
      <div className="w-100 h-100 d-flex justify-center items-center">
        No data available
      </div>
    );
  }

  const maxSoldValue = dashboardTopProducts[0]?.sold;
  return maxSoldValue === 0 ? (
    <div className="w-100 h-100 d-flex justify-center items-center">
      No product sold yet
    </div>
  ) : (
    <table className="table h-100">
      <colgroup>
        <col className="hidden md:table-column" style={{ width: "6%" }} />
        <col style={{ width: "16%" }} />
        <col style={{ width: "30%" }} />
        <col style={{ width: "4%" }} />
      </colgroup>
      <thead>
        <tr style={headingStyle}>
          {columns.map((column, index) => (
            <th
              key={index}
              style={{ color: "rgba(150, 165, 184, 1)" }}
              className={index === 0 ? "hidden md:table-cell" : ""}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dashboardTopProducts?.map((product, index) => {
          const isLastRow = index === dashboardTopProducts.length - 1;
          return (
            <tr key={index} style={itemStyle}>
              <td
                className="hidden md:table-cell"
                style={{
                  color: "rgba(68, 74, 109, 1)",
                  verticalAlign: "middle",
                  borderBottom: isLastRow ? "none" : "1px solid #ddd",
                }}
              >{`0${index + 1}`}</td>
              <td
                style={{
                  color: "rgba(68, 74, 109, 1)",
                  verticalAlign: "middle",
                  borderBottom: isLastRow ? "none" : "1px solid #ddd",
                }}
              >
                {product.productName}
              </td>
              <td
                style={{
                  color: "rgba(68, 74, 109, 1)",
                  verticalAlign: "middle",
                  borderBottom: isLastRow ? "none" : "1px solid #ddd",
                }}
              >
                <ProgressBar value={(product.sold / maxSoldValue) * 100} />
              </td>
              <td
                style={{
                  color: "rgba(68, 74, 109, 1)",
                  verticalAlign: "middle",
                  borderBottom: isLastRow ? "none" : "1px solid #ddd",
                }}
              >
                <button
                  className="d-flex justify-center items-center w-[65px] h-[24px]"
                  style={{
                    border: "1px solid rgba(224, 111, 44, 1)",
                    color: "rgba(224, 111, 44, 1)",
                    borderRadius: "6px",
                  }}
                >
                  <span style={headingStyle}>{product.sold}</span>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableComp;
