import React from "react";
import osfy from "../../assets/osfy.svg";

const PoweredBy = () => {
  return (
    <div className="flex flex-row gap-[7px] items-center justify-center">
      <span
        className="text-[14px] text-[#525252] font-normal"
        style={{
          lineHeight: "21px",
        }}
      >
        powered by:{" "}
      </span>
      <a
        className="cursor-pointer"
        href="https://osfy.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={osfy} alt="osfy" />
      </a>
    </div>
  );
};

export default PoweredBy;
