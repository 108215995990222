import React, { useEffect, useState } from "react";
import SettingOptionChip from "../Common/settingOptionChip";
import PoweredBy from "../Common/PoweredBy";
import { useLocation } from "react-router-dom";

const SettingLeftLayout = ({ settingsChildren }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const selectedItem = settingsChildren.find((item) =>
      location.pathname.includes(item.path)
    );

    if (selectedItem) {
      setSelectedOption(selectedItem.id);
    }
  }, [location.pathname, settingsChildren]);

  return (
    <div className="flex flex-col h-full w-[241px] bg-white rounded-lg pt-[2rem] py-[2rem] pl-[17px] pr-[12px] flex-shrink-0">
      <div className="flex flex-col gap-2 flex-grow-1">
        <div className="font-bold text-[20px] mb-2 text-[#12141E] pl-[12px]">Settings</div>
        {settingsChildren.map((child) => (
          <SettingOptionChip
            key={child.id}
            name={child.name}
            isSelected={selectedOption === child.id}
            onClick={() => setSelectedOption(child.id)}
            path={child.path}
          />
        ))}
      </div>
      <div>
        <PoweredBy />
      </div>
    </div>
  );
};

export default SettingLeftLayout;
