import React, { useContext } from "react";
import DoughnutChart from "./Doughnut";
import { AuthContext } from "../../auth/AuthProvider";
import { Spinner } from "@chakra-ui/react";

function OrderPie() {
  const { dashboardOrders, loading } = useContext(AuthContext);

  return (
    <div className="d-flex flex-column h-100 w-100 px-2 md:px-1">
      <div className="px-4 pt-3">
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "2rem",
            textAlign: "left",
          }}
        >
          Orders
        </div>
        <div
          style={{
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "21.82px",
            textAlign: "left",
            color: "#CDCBCA",
          }}
        >
          Total
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-center items-center w-100">
          <Spinner color="#E06F2C" />
        </div>
      ) : (
        <div className="flex w-full h-full flex-col md:flex-row">
          <div className="w-full md:w-[40%] flex items-center md:justify-center ml-6 md:ml-0 mt-4  order-2 md:order-1">
            <div className="flex flex-col gap-4">
              {/* Camper */}
              <div className="d-flex">
                <div
                  className="w-[35px] h-[18px] bg-chocolate-200 self-center me-[10px]"
                  style={{ borderRadius: "33px" }}
                ></div>
                <div className="d-flex w-[130px]">
                  <div
                    className="self-center"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12.75px",
                      fontWeight: "700",
                      lineHeight: "12.61px",
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    {dashboardOrders?.totalOrders === 0
                      ? 0.0
                      : (
                          (dashboardOrders?.camperOrders /
                            dashboardOrders?.totalOrders) *
                          100
                        ).toFixed(2)}
                    % <span style={{ fontWeight: "500" }}>Camper</span>
                  </div>
                </div>
              </div>

              {/* Staff */}
              <div className="d-flex">
                <div
                  className="w-[35px] h-[18px] bg-chocolate-500 self-center me-[10px]"
                  style={{ borderRadius: "33px" }}
                ></div>
                <div className="d-flex w-[130px]">
                  <div
                    className="self-center"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12.75px",
                      fontWeight: "700",
                      lineHeight: "12.61px",
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    {dashboardOrders?.totalOrders === 0
                      ? 0
                      : (
                          (dashboardOrders?.staffOrders /
                            dashboardOrders?.totalOrders) *
                          100
                        ).toFixed(2)}
                    %{" "}
                    <span
                      style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.7)" }}
                    >
                      Staff
                    </span>
                  </div>
                </div>
              </div>

              {/* Cost */}
              <div className="d-flex">
                <div
                  className="w-[35px] h-[18px] bg-chocolate-600 self-center me-[10px]"
                  style={{ borderRadius: "33px" }}
                ></div>
                <div className="d-flex w-[130px]">
                  <div
                    className="self-center"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12.75px",
                      fontWeight: "700",
                      lineHeight: "12.61px",
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    {dashboardOrders?.totalOrders === 0
                      ? 0
                      : (
                          (dashboardOrders?.costOrders /
                            dashboardOrders?.totalOrders) *
                          100
                        ).toFixed(2)}
                    %{" "}
                    <span
                      style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.7)" }}
                    >
                      Cost
                    </span>
                  </div>
                </div>
              </div>

              {/* Cash */}
              <div className="d-flex">
                <div
                  className="w-[35px] h-[18px]  self-center me-[10px]"
                  style={{ borderRadius: "33px", backgroundColor: "#BC4D0C" }}
                ></div>
                <div className="d-flex w-[130px]">
                  <div
                    className="self-center"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12.75px",
                      fontWeight: "700",
                      lineHeight: "12.61px",
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                  >
                    {dashboardOrders?.totalOrders === 0
                      ? 0
                      : (
                          (dashboardOrders?.cashOrders /
                            dashboardOrders?.totalOrders) *
                          100
                        ).toFixed(2)}
                    %{" "}
                    <span
                      style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.7)" }}
                    >
                      Cash
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-[60%] md:-mt-6 flex items-center justify-center md:order-2">
            <DoughnutChart dashboardData={dashboardOrders} />
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderPie;
