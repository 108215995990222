export const accountTypeItems = [
  { label: "Camper", value: "Camper" },
  { label: "Staff", value: "Staff" },
  { label: "Cost", value: "Cost" },
];

export const boldStyle1 = {
  fontSize: "15px",
  fontWeight: " 500",
  lineHeight: "22.5px",
  textAlign: "center",
};

export const boldStyle2 = {
  fontSize: "16.5px",
  fontWeight: " 600",
  lineHeight: "24.75.5px",
  textAlign: "center",
};

export const buttonStyle = {
  color: "#fff",
  backgroundColor: "#E06F2C",
  borderRadius: "7px",
  textAlign: "center",
};
