import React, { useContext, useEffect, useState } from "react";
import InputModalContent from "./inputModalContent";
import DropDownList from "./dropdownList";
import { itemsType } from "../../data/AccountsData";
import { AuthContext } from "../../auth/AuthProvider.js";
import toast from "react-hot-toast";
import axios from "axios";

const AccountModalContent = (props) => {
  const [selectedType, setSelectedType] = useState(null);
  const { user, token } = useContext(AuthContext);

  const formattedBunks = user.bunks?.map((bunk) => {
    return { label: bunk.bunkNumber, value: bunk.bunkNumber };
  });

  const bunkItems = formattedBunks || [];

  const [newAccount, setNewAccount] = useState({
    name: "",
    accountType: "",
    autoGenerateNo: props.autoGenNumber,
    bunk: "1",
    balance: "",
    spendingLimits: "",
    userId: user.id,
  });
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

  const fetchAutoGenerateNo = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/getAccountNumber`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewAccount({
        ...newAccount,
        autoGenerateNo: response.data.data.autoGenerateNo,
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const validateForm = () => {
    const { name, accountType, balance, spendingLimits } = newAccount;
    if (name && accountType) {
      if (accountType === "Camper" && balance) {
        return true;
      } else if (
        (accountType === "Staff" || accountType === "Cost") &&
        spendingLimits
      ) {
        return true;
      } else if (
        accountType !== "Camper" &&
        accountType !== "Staff" &&
        accountType !== "Cost"
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setIsCreateButtonDisabled(!validateForm());
  }, [newAccount, selectedType]);

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsCreateButtonDisabled(true);

    let payload = {
      name: newAccount.name.toLowerCase(),
      autoGenerateNo: newAccount.autoGenerateNo,
      accountType: newAccount.accountType,
      userId: newAccount.userId,
    };

    if (selectedType === "Camper") {
      payload = {
        ...payload,
        bunk: newAccount.bunk,
        balance: newAccount.balance,
      };
    } else if (selectedType === "Staff" || selectedType === "Cost") {
      payload = {
        ...payload,
        spendingLimits: newAccount.spendingLimits,
      };
    }

    try {
      toast.loading("Please wait...");
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/create`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.addAccount({ ...payload, ...res.data.data });
        props.onClose();
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "balance" || id === "spendingLimits") {
      if (!isNaN(value)) {
        setNewAccount({ ...newAccount, [id]: value });
      }
    } else {
      setNewAccount({ ...newAccount, [id]: value });
    }
  };

  const handleTypeChange = (selectedValue) => {
    setSelectedType(selectedValue);
    setNewAccount({ ...newAccount, accountType: selectedValue });
  };

  const handleBunkChange = (selectedValue) => {
    setNewAccount({ ...newAccount, bunk: selectedValue });
  };

  return (
    <div className="p-[20px] flex flex-col gap-[25px]">
      <div
        className="font-bold"
        style={{ fontSize: "16px", lineHeight: "24px", color: "#12141E" }}
      >
        New Account
      </div>
      <div className="px-[42px] h-[225px] flex flex-col gap-[19px]">
        <div className="flex flex-row justify-between">
          <div className="w-[55%]">
            <InputModalContent
              name="Name"
              onChange={handleChange}
              value={newAccount.name}
              id="name"
            />
          </div>
          <div className="w-[35%]">
            <InputModalContent
              name="Auto Gen Number"
              trailing="true"
              value={newAccount.autoGenerateNo}
              id="autoGenerateNo"
              disabled={true}
              onClick={fetchAutoGenerateNo}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <div className="w-[55%] flex flex-row justify-between">
            <div className={selectedType !== "Camper" ? "w-full" : "w-[60%]"}>
              <DropDownList
                name="Type"
                items={itemsType}
                value={selectedType}
                id="accountType"
                onChange={handleTypeChange}
              />
            </div>
            {selectedType === "Camper" && (
              <div className="w-[35%]">
                <DropDownList
                  name="Bunk"
                  items={bunkItems}
                  value={newAccount.bunk}
                  onChange={handleBunkChange}
                />
              </div>
            )}
          </div>
          <div className="w-[35%]">
            <InputModalContent
              name={
                selectedType && selectedType !== "Camper"
                  ? "Spending Limits"
                  : "Balance"
              }
              leading={true}
              id={
                selectedType && selectedType !== "Camper"
                  ? "spendingLimits"
                  : "balance"
              }
              value={
                selectedType && selectedType !== "Camper"
                  ? newAccount.spendingLimits
                  : newAccount.balance
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="d-flex justify-center items-center gap-[11px] mt-3">
          <button
            className="h-[30px] w-[94px]"
            style={{
              backgroundColor: "#E0E0E0",
              borderRadius: "5px",
              color: "#000000",
            }}
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            className={`h-[30px] w-[94px] ${
              isCreateButtonDisabled ? "cursor-not-allowed" : "cursor - pointer"
            }`}
            style={{
              backgroundColor: isCreateButtonDisabled
                ? "rgba(224, 111, 44, 0.43)"
                : "#E06F2C",
              borderRadius: "5px",
              color: "#fff",
            }}
            onClick={handleCreate}
            disabled={isCreateButtonDisabled}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountModalContent;
