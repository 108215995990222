import React from "react";
import { Link } from "react-router-dom";

const LayoutItem = (props) => {
  return (
    <Link
      to={props.path}
      className={`flex items-center justify-center w-[32px] h-[32px] ${
        props.isSelected !== props.name
          ? "transition duration-300 ease-in-out hover:bg-gray-500"
          : ""
      }`}
      style={{ borderRadius: "4px" }}
      onClick={props.onClick}
    >
      {props.isSelected === props.name ? (
        <img src={props.SelectedIcon} alt={props.name} />
      ) : (
        <img src={props.defaultIcon} alt={props.name} />
      )}
    </Link>
  );
};

export default LayoutItem;
