import { Spinner } from "@chakra-ui/react";
import React from "react";

const LoadingSpinner = ({ isLoading }) => {
  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-25  flex justify-center items-center ${
        isLoading ? "block" : "hidden"
      }`}
      id="wrapper"
    >
      <div className="w-[400px]">
        <div className="md:w-[400px] w-[90%] mx-auto flex flex-col items-center">
          {" "}
          <Spinner color="#FF8943" />
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
