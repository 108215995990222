import React from "react";

function ProgressBar({value}) {
  return (
    <div className="progress" style={{height: "4px", width:"85%", backgroundColor:"rgba(255, 220, 199, 1)"}}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{width:`${value}%`, backgroundColor:"rgba(224, 111, 44, 1)"}}
        aria-valuenow= {value}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}

export default ProgressBar;
