import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import Cross from "../../assets/Cross.svg";
function RemoveModal(props) {

  const [RemovedStock, setRemovedStock] = useState(0);
    const handleSwitch = () =>{
        props.handleClose();
        props.handleShowAdd();
    }

    const handleRemovedStock = () =>{
      props.handleRemoveStock(RemovedStock);
      setRemovedStock(0);
      props.handleClose();
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="custom-modal"
      >
        <div className="d-flex flex-column w-[420px] h-[262px]">
          <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
            <div
              style={{
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "31.5px",
                marginLeft: "33px",
              }}
            >
              Remove Stock
            </div>
            <button
              className="w-[15.5px] h-[15.5px] me-[23px]"
              onClick={props.handleClose}
            >
              <img src={Cross} alt="cross" />
            </button>
          </div>
          <div
            className="h-[20px]"
            style={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "19.5px",
              marginLeft: "33px",
              color: "rgba(0, 0, 0, 0.28)",
            }}
          >
            {props.data.productName}
          </div>
          <div className="d-flex justify-center items-center w-100 h-[40px] mt-[37px]">
            <div
              className="d-flex items-center justify-center w-[230px] h-[40px] "
              style={{ background: "#f8f8f8", borderRadius: "9px" }}
            >
              <input
                placeholder="Amount"
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  backgroundColor: "#f8f8f8",
                  paddingLeft:"20px"
                }}
                className="custom-placeholder"
                onChange={(e) => setRemovedStock(parseInt(e.target.value))}
              />
            </div>
          </div>
          <div className="d-flex justify-center items-center h-[30px] mt-[19px]">
            <button
              className="h-[30px] w-[94px]"
              style={{
                backgroundColor: "#FF8943",
                borderRadius: "5px",
                color: "#fff",
              }}
              onClick= {() => handleRemovedStock()}
            >
              Remove
            </button>
          </div>
          <div className="d-flex justify-center h-[20px] mt-[14px]">
            <button
             onClick={handleSwitch}
              className="h-[20px]"
              style={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "18px",
                color: "rgba(0, 0, 0, 0.28)",
                
              }}
            >
              Add Stock
            </button>
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .custom-modal .modal-content {
          border-radius: 20px;
          width: 420px;
        }

        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
    </>
  );
}

export default RemoveModal;
