import React from "react";
import BarChart from "./BarGraph";

function SalesandProfit() {
  return (
    <div className="flex flex-column h-full w-full gap-2 md:gap-3 px-2 md:px-1 ">
      <div className="px-4 pt-3">
        <div
          className=""
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "32px",
            color: "#000000",
          }}
        >
          Sales & Profits{" "}
        </div>
        <div
          style={{
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "21.82px",
            textAlign: "left",
            color: "#CDCBCA",
          }}
        >
          Last 7 days
        </div>
      </div>
      <div className="d-flex justify-center items-center h-full md:h-[80%] px-2 ">
        <BarChart />
      </div>
    </div>
  );
}

export default SalesandProfit;
