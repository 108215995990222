import React from "react";
import TableComp from "./TableComp";

function TopProducts() {
  return (
    <div className="d-flex flex-column h-100 w-100 px-2 md:px-1 gap-2 ">
      <div className="h-[15%] px-4 pt-3">
        <div
          className="w-[175px] h-[32px]"
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "32px",
          }}
        >
          Top Products
        </div>
      </div>
      <div className="h-[80%] px-2">
        <TableComp />
      </div>
    </div>
  );
}

export default TopProducts;
