import React from "react";

const DeleteContent = (props) => {
  return (
    <div className="w-[100%] h-[219px] flex items-center justify-center ">
      <div className="flex flex-col justify-center items-center space-y-[36px] h-[100%] w-[100%]">
        <div
          className="w-[80%] h-[64px]"
          style={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: "21px",
            lineHeight: "31.5px",
            color: "#636363",
          }}
        >
          {props.delete === "false"
            ? `Are you sure you want to cancel this order?`
            : `Are you sure you want to Delete this ${props.title}, ${props.name}?`}
        </div>

        <div className="flex space-x-[11px]">
          <button
            className="btn"
            onClick={props.handleClose}
            disabled={props.isLoading}
            style={{
              width: "87px",
              height: "31px",
              backgroundColor: "#E0E0E0",
              borderRadius: "5px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "400",
              color: "#000000",
              opacity: props.isLoading ? 0.5 : 1,
            }}
          >
            Cancel
          </button>
          <button
            className="btn"
            onClick={props.handleDelete}
            disabled={props.isLoading}
            style={{
              height: "31px",
              backgroundColor: "#E06F2C",
              borderRadius: "5px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "500",
              color: "#FFFFFF",
              opacity: props.isLoading ? 0.5 : 1,
            }}
          >
            {props.isLoading
              ? "Processing..."
              : props.delete === "false"
              ? `Yes, Cancel & Refund`
              : "Yes, Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteContent;
