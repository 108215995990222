import React, { useState } from 'react';
import { FaSyncAlt, FaCheck } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SpinningIcon = styled(FaSyncAlt)`
  animation: ${spin} 1s linear infinite;
`;

function Refresh({ check, loadingRefresh}) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [showRefresh, setShowRefresh] = useState(true);
  

  const refreshCanvas = () => {
    setIsSpinning(true);
    setShowRefresh(true);
    setTimeout(() => {
      setIsSpinning(false);
      check && setShowRefresh(false);
    }, 1000);
  };

  return (
    <IconWrapper onClick={refreshCanvas}>
      {showRefresh ? (
        (loadingRefresh || isSpinning) ? (
          <SpinningIcon />
        ) : (
          <FaSyncAlt />
        )
      ) : (
        check && <FaCheck />
      )}
    </IconWrapper>
  );
}

export default Refresh;