import React, {useContext} from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "../auth/AuthProvider";
import toast from "react-hot-toast";
import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function LogoutModal(props) {
  const {loading, logoutUser} = useContext(AuthContext);
  const navigate = useNavigate();
  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  const handleLogout = async () => {
    try {
      toast.loading("Please wait...");
      await logoutUser();
      toast.dismiss();
      toast.success("Logout successful!");
    } catch (error) {
      toast.dismiss();
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="w-25 min-w-[330px]"
      >
        <div
          className="d-flex justify-center items-center h-[100px]"
        >
          <div
            className="w-[260px] text-center"
            style={{
              color: "rgba(99, 99, 99, 1)",
              fontWeight: "500",
              fontSize: "21px",
              lineHeight: "31px",
            }}
          >
            Are you sure you want to Logout
          </div>
        </div>
        <div className="d-flex justify-center h-[70px] py-[6px]">
          <div>
            <Button
              onClick={props.handleClose}
              style={{
                marginRight: "20px",
                backgroundColor: "rgba(224, 224, 224, 1)",
                color: "#000",
                border: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleLogout()}
              style={{
                backgroundColor: "rgba(224, 111, 44, 1)",
                color: "#fff",
                border: "none",
              }}
            >
              Yes, Logout
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LogoutModal;
