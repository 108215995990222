import React, { useState, useContext, useEffect } from "react";
import SearchBar from "../components/ProductsPageComponent/SearchBar";
import ProductsTable from "../components/ProductsPageComponent/ProductsTable";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Categories from "../components/ProductsPageComponent/categories";
import { useNavigate } from "react-router-dom";
import BulkUploadContent from "./ProductsPageComponent/bulkUploadContent";
import Modal from "./Common/modal";
import { AuthContext } from "../auth/AuthProvider";
import { getCategoryNameById } from "../utils";
import LoadingSpinner from "./Common/loadingSpinner";
import { MdLocalPrintshop } from "react-icons/md";
import MenuContent from "./ProductsPageComponent/MenuContent/MenuContent";

const Products = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const {
    user,
    token,
    categories,
    setCategories,
    productsData,
    setProductsData,
    loading,
  } = useContext(AuthContext);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showMenuModal, setShowMenuModal] = useState(false);

  const toggleMenuModal = (isOpen) => {
    setShowMenuModal(isOpen);
  };

  const navigate = useNavigate();

  const handleAddProduct = () => {
    navigate("/products/product-details");
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 0) {
      const filteredProducts = productsData.filter((product) =>
        product.productName
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(value.toLowerCase().replace(/\s+/g, ""))
      );
      setSuggestions(filteredProducts);
    } else {
      setSuggestions([]);
    }
  };

  const handleUpload = (uploadedProducts) => {
    const newCategories = uploadedProducts
      .map((product) => ({
        categoryName: product.category,
        categoryId: product.categoryId,
      }))
      .filter(
        (newCat) =>
          !categories.some(
            (cat) =>
              cat.categoryName.toLowerCase() ===
              newCat.categoryName.toLowerCase()
          )
      );

    setCategories((prevCategories) => [...prevCategories, ...newCategories]);

    setProductsData((prevData) => [...prevData, ...uploadedProducts]);
  };

  const downloadProductsCSV = () => {
    const header = [
      "Product Name",
      "Category",
      "Cost",
      "Price",
      "Stock",
      "Sold",
      "Sales",
      "Profit",
    ];

    const csvRows = productsData.map((product) => [
      product.productName,
      getCategoryNameById(product.categoryId, categories) || "Not defined",
      "$" + product.cost,
      "$" + product.price,
      product.stock,
      product.sold,
      "$" + product.sales,
      "$" + product.profits,
    ]);

    const csvContent = [header, ...csvRows]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "products.csv");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div
        className="w-full h-full px-[42px] pt-[38px] bg-white"
        style={{ borderRadius: "20px" }}
      >
        <div className="d-flex flex-col w-[100%] h-[100%] justify-between">
          <div
            style={{ boxShadow: "8px 16px 58px 0px rgba(0, 0, 0, 0.03)" }}
            className="d-flex flex-col w-[100%] h-[30%] justify-between"
          >
            <div className="d-flex flex-col md:flex-row justify-between w-[100%] gap-[2%] h-[20%] min-h-[40px]">
              <div className="d-flex flex-row justify-between w-[100%] lg:w-[50%]">
                <div
                  style={{
                    fontSize: "24px",
                    color: "#12141E",
                    fontWeight: "700",
                    lineHeight: "36px",
                  }}
                >
                  Products
                </div>

                <SearchBar
                  placeholder={"Search Products"}
                  onChange={handleChange}
                  value={query}
                />
              </div>

              <div className="d-flex flex-row justify-between w-[100%] lg:w-[50%]">
                <Button
                  variant={"outline"}
                  leftIcon={<MdLocalPrintshop size={20} />}
                  colorScheme="gray"
                  onClick={() => toggleMenuModal(true)}
                >
                  Print Menu
                </Button>
                <Button
                  variant={"outline"}
                  leftIcon={<AddIcon />}
                  colorScheme="gray"
                  onClick={handleShowModal}
                >
                  Bulk Upload
                </Button>
                <Button
                  variant={"outline"}
                  leftIcon={<AddIcon />}
                  colorScheme="gray"
                  onClick={handleAddProduct}
                >
                  Add
                </Button>
                <Button
                  variant={"outline"}
                  colorScheme="gray"
                  onClick={downloadProductsCSV}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="flex flex-row items-center min-h-[132px] hide-scrollbar overflow-x-auto w-[100%] h-[80%]">
              <Categories
                categories={categories}
                setCategories={setCategories}
                isLoading={loading}
                setSelectedCategoryId={setSelectedCategoryId}
              />
            </div>
          </div>

          <div className="w-[100%] h-[70%] bg-white d-flex justify-center">
            <div className="w-[100%] h-full custom-scrollbar overflow-y-auto ">
              <ProductsTable
                categories={categories}
                selectedCategoryId={selectedCategoryId}
                data={productsData}
                setData={setProductsData}
                suggestions={suggestions}
                query={query}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isVisible={showModal} onClose={handleCloseModal}>
        <BulkUploadContent
          handleClose={handleCloseModal}
          handleUpload={handleUpload}
          categories={categories}
          token={token}
          userId={user.id}
        />
      </Modal>

      <Modal isVisible={showMenuModal} onClose={() => toggleMenuModal(false)}>
        <MenuContent
          handleClose={() => toggleMenuModal(false)}
          categories={categories}
          products={productsData}
        />
      </Modal>

      <LoadingSpinner isLoading={loading} />
    </>
  );
};

export default Products;
