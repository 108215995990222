import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "recharts";
import { Spinner } from "@chakra-ui/react";

const ThirtyDayBarChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const [yAxisOptions, setYAxisOptions] = useState({
    max: 2000,
    stepSize: 500,
  });
  const [monthlyData, setMonthlyData] = useState(null);

  useEffect(() => {
    if (data) {
      setMonthlyData(data);
    }
  }, [data]);

  const calculateYAxisOptions = (chartData) => {
    if (!chartData || !chartData.datasets) return { max: 1000, stepSize: 200 };

    const allValues = chartData.datasets.flatMap((dataset) => dataset.data);
    const maxValue = Math.max(...allValues);

    let stepSize, max;
    if(maxValue <= 100) {
      stepSize = 20;
      max = 100;
    } 
    else if (maxValue <= 500) {
      stepSize = 50;
      max = 500;
    } else if (maxValue <= 1000) {
      stepSize = 200;
      max = 1000;
    } else if (maxValue <= 2000) {
      stepSize = 250;
      max = 2000;
    } else if (maxValue <= 5000) {
      stepSize = 500;
      max = 5000;
    } else {
      stepSize = 1000;
      max = Math.ceil(maxValue / 1000) * 1000;
    }

    return { max, stepSize };
  };

  useEffect(() => {
    const labels = monthlyData?.map((item) => item.day);

    const datasets = [
      {
        label: "Sales",
        data: monthlyData?.map((item) => item.sales),
        backgroundColor: "rgba(255, 220, 199, 1)",
        barPercentage: 2,
        barThickness: 8,
        borderRadius: 4,
      },
      {
        label: "Profit",
        data: monthlyData?.map((item) => item.profits),
        backgroundColor: "rgba(224, 111, 44, 1)",
        barPercentage: 0.5,
        borderRadius: 4,
      },
    ];

    const newChartData = { labels, datasets };
    setChartData(newChartData);
    setYAxisOptions(calculateYAxisOptions(newChartData));
  }, [monthlyData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },

      tooltip: {
        callbacks: {
          title: (context) => `Day ${context[0].label}`,
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        barPercentage: 0.2,
        categoryPercentage: 0.9,
        ticks: {
          callback: function (value) {
            return monthlyData[value]?.day;
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value >= 1000 ? (value / 1000).toFixed(1) + "k" : value;
          },
          stepSize: yAxisOptions.stepSize,
        },
        max: yAxisOptions.max,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
  };

  if (!chartData && !monthlyData)
    return (
      <div className="flex items-center justify-center h-64 w-full">
        <Spinner color="#E06F2C" />
      </div>
    );

  return (
    <div className="w-full h-full p-4 bg-white">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ThirtyDayBarChart;
