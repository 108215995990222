import React, { Fragment } from "react";
import TransactionsTable from "./Common/transactionsTable";
import { AuthContext } from "../auth/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import LoadingSpinner from "./Common/loadingSpinner";

function Transactions() {
  return (
    <Fragment>
      <div
        className="h-[100%] w-[100%] px-[42px] py-[38px] bg-white"
        style={{ borderRadius: "20px" }}
      >
        <div className="flex flex-col w-[100%] h-[100%] justify-between">
          <div className="w-[100%] h-[40px]">
            <div className="d-flex flex-row justify-between w-[100%] gap-[2%] h-[20%] min-h-[40px]">
              <div className="d-flex flex-row justify-between w-[50%]">
                <div
                  style={{
                    fontSize: "24px",
                    color: "#12141E",
                    fontWeight: "700",
                    lineHeight: "36px",
                  }}
                >
                  Online Transactions
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%] h-[92%] bg-white d-flex justify-center">
            <div className="w-[100%] custom-scrollbar overflow-auto">
              <TransactionsTable
                // accountData={accounts}
                // query={query}
                // suggestions={suggestions}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Transactions;
