import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../routes";
import LeftLayout from "./LeftLayout";
import Header from "./Header";

const Layout = () => {
  return (
    <div className="flex flex-row h-screen w-screen bg-[#F8F8F8]">
      {/* Header */}
      <div className="md:hidden">
        <Header />
      </div>

      {/* Left layout */}
      <div className="hidden md:inline md:h-[100%]">
        <LeftLayout />
      </div>

      {/* Main content */}
      <div className="flex flex-grow pt-[87px] md:pt-[2rem] py-[2rem] px-[2rem] h-[100%] w-full overflow-hidden">
        <Routes>
          {routes.map(({ layout, pages }) => {
            if (layout === "admin") {
              return pages.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ));
            }
          })}
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
