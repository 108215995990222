import React, { useRef, useState, useEffect, useContext } from "react";
import backArrow from "../../assets/backArrow.svg";
import { GoDotFill } from "react-icons/go";
import Edit_light from "../../assets/Edit_light.svg";
import { Switch } from "antd";
import AccountDropdown from "./AccountDropdown";
import BalanceHistoryTable from "./BalanceHistoryTable";
import { accountTypeItems } from "../../data/AccountViewData";
import {
  boldStyle1,
  boldStyle2,
  buttonStyle,
} from "../../data/AccountViewData";
import OrdersTable from "./OrdersTable";
import AddBalance from "./AddRemoveBalanceModal/AddBalance";
import RemoveBalance from "./AddRemoveBalanceModal/RemoveBalance";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../auth/AuthProvider";
import axios from "axios";
import LoadingSpinner from "../Common/loadingSpinner";
import toast from "react-hot-toast";
import Modal from "../Common/modal";
import DeleteContent from "../Common/deleteContent";
import { formatBalance, formatDate, getOrderedItems } from "../../utils";
import { Spinner } from "react-bootstrap";

function AccountView() {
  const [isEditing, setIsEditing] = useState(false);
  const accNumRef = useRef(null);
  const accNameRef = useRef(null);
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState({});
  const { user, token, setAccounts } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { accountId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const handleCloseRemove = () => setShowRemove(false);
  const handleShowRemove = () => setShowRemove(true);
  const [ordersData, setOrdersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const formattedBunks = user.bunks?.map((bunk) => {
    return { label: bunk.bunkNumber, value: bunk.bunkNumber };
  });

  const bunkItems = formattedBunks || [];

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const [originalAutoGenerateNo, setOriginalAutoGenerateNo] = useState("");

  useEffect(() => {
    setOriginalAutoGenerateNo(accountData.autoGenerateNo);
  }, [accountData.accountId]);

  const handleSaveClick = async () => {
    if (accountData.autoGenerateNo !== originalAutoGenerateNo) {
      if (accountData.autoGenerateNo.length !== 4) {
        setErrorMessage("Acc/No must be 4 digits long");
      } else {
        setIsLoading(true);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_HOST_URL}/api/pos/account/checkUniqueAccountNumber`,
            {
              userId: user.id,
              autoGenerateNo: parseInt(accountData.autoGenerateNo),
            },
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data.success) {
            setIsEditing(false);
            setErrorMessage("");
            setAccountData({
              ...accountData,
              autoGenerateNo: response.data.data.autoGenerateNo,
            });
          } else {
            toast.error(
              "This account number is already in use. Please choose a different number."
            );
          }
        } catch (error) {
          if (error.response && error.response.status === 409) {
            toast.error(
              "This account number is already in use. Please choose a different number."
            );
          } else {
            toast.error(
              "An error occurred while checking the account number. Please try again."
            );
          }
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setIsEditing(false);
      setErrorMessage("");
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (isEditing) {
      accNumRef.current.focus();
      accNumRef.current.select();
      accNameRef.current.focus();
      accNameRef.current.select();
    }
  }, [isEditing]);

  const onChangeDisable = (checked) => {
    setAccountData({ ...accountData, isDisabled: checked });
  };

  const handleAddBalance = (value, method) => {
    const addedAmount = parseFloat(value);
    const currentBalance = parseFloat(accountData.balance || 0);
    const newBalance = parseFloat(currentBalance + addedAmount).toFixed(2);
    const transaction = {
      amount: addedAmount,
      type: "deposit",
      method: method,
      timestamp: new Date().toISOString(),
    };
    setAccountData({
      ...accountData,
      balance: newBalance,
      balanceHistory: [...accountData.balanceHistory, transaction],
    });
    setBalanceHistory([...balanceHistory, transaction]);
  };

  const handleRemoveBalance = (value, method) => {
    const removedAmount = parseFloat(value);
    const currentBalance = parseFloat(accountData.balance || 0);
    if (removedAmount > currentBalance) {
      toast.error("Amount to remove is greater than the current balance");
      return;
    }
    let newBalance = parseFloat(currentBalance - removedAmount).toFixed(2);
    if (newBalance < 0) {
      newBalance = 0;
    }

    const transaction = {
      amount: removedAmount,
      type: "withdrawal",
      method: method,
      timestamp: new Date().toISOString(),
    };
    setAccountData({
      ...accountData,
      balance: newBalance,
      balanceHistory: [...accountData.balanceHistory, transaction],
    });
    setBalanceHistory([...balanceHistory, transaction]);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setAccountData({ ...accountData, [e.target.id]: e.target.value });
    if (e.target.id === "autoGenerateNo" && e.target.value.length !== 4) {
      setErrorMessage("Acc/No must be 4 digits long");
    } else {
      setErrorMessage("");
    }
  };

  const fetchAccountData = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id, accountId: accountId };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/get`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccountData(response.data.data);
      setOrdersData(response.data.data.orders);
      setBalanceHistory(response.data.data.balanceHistory);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  const handleUpdate = async () => {
    try {
      toast.loading("Updating account...");
      const payload = {
        ...accountData,
        userId: user.id,
        name: accountData.name.toLowerCase(),
      };
      console.log(payload);

      const res = await axios.put(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/update`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res && res.data.success) {
        setAccounts((prevAccounts) => {
          const updatedAccounts = prevAccounts.map((account) => {
            if (account.accountId === accountId) {
              return { ...account, ...accountData };
            }
            return account;
          });
          return updatedAccounts;
        });
        toast.dismiss();
        toast.success("Account updated successfully");
      } else {
        toast.dismiss();
        toast.error("Failed to update account");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleteLoading(true);
      toast.loading("Deleting account...");
      const payload = { userId: user.id, accountId: accountId };
      const res = await axios.delete(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/delete`,
        {
          data: payload,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res && res.data.success) {
        setAccounts((prevAccounts) =>
          prevAccounts.filter((account) => account.accountId !== accountId)
        );

        toast.dismiss();
        toast.success("Account deleted successfully");
        navigate("/accounts");
      } else {
        toast.dismiss();
        toast.error("Failed to delete account");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setIsDeleteLoading(false);
      toggleDeleteModal();
    }
  };

  function SumFunction(orders, param) {
    let sum = 0;

    orders.forEach((order) => {
      switch (param) {
        case "sales":
          sum += order.total;
          break;
        case "items":
          // sum += order.orderedItems.length;
          sum += getOrderedItems(order);
          break;
        case "profits":
          sum += order.profit;
          break;
        default:
          break;
      }
    });

    return sum;
  }

  const handleCancelOrder = (orderId) => {
    setOrdersData((prevOrders) => {
      const canceledOrder = prevOrders.find(
        (order) => order.orderId === orderId
      );
      const updatedOrders = prevOrders.filter(
        (order) => order.orderId !== orderId
      );

      const method = canceledOrder.method;

      setAccountData((prevData) => ({
        ...prevData,
        balance:
          method === "Account"
            ? (
                parseFloat(prevData.balance) + parseFloat(canceledOrder.total)
              ).toFixed(2)
            : parseFloat(prevData.balance).toFixed(2),
        items: prevData.items - getOrderedItems(canceledOrder),
        sales: prevData.sales - canceledOrder.total,
        profits: prevData.profits - canceledOrder.profit,
      }));

      if (method === "Account") {
        const refundTransaction = {
          amount: canceledOrder.total,
          type: "deposit",
          method: "refunded",
          timestamp: formatDate(new Date()),
        };
        setBalanceHistory((prevHistory) => [...prevHistory, refundTransaction]);
      }
      return updatedOrders;
    });
  };

  return loading ? (
    <div className="d-flex justify-center items-center h-[100%] w-[100%]">
      <Spinner />
    </div>
  ) : (
    <div
      className="d-flex flex-column h-100 w-100 bg-white px-[40px] pb-[20px]"
      style={{ borderRadius: "20px" }}
    >
      <div className="d-flex items-center h-[9%] justify-between">
        <button
          className=" d-flex items-center justify-between w-[60px] h-[21px] mt-[20px] transition duration-300 ease-in-out hover:bg-gray-500"
          style={{ borderRadius: "4px" }}
          onClick={handleBackButton}
        >
          <img src={backArrow} width="12px" height="13.4px" alt="arrow" />
          <div
            className="w-[35px] h-[21px] text-align-center"
            style={{ fontWeight: "500", fontSize: "14px", lineHeight: "21px" }}
          >
            Back
          </div>
        </button>
        <button
          className="btn w-[107px] h-[40px]"
          style={{
            backgroundColor: "rgba(224, 111, 44, 1)",
            color: "#fff",
          }}
          onClick={handleUpdate}
        >
          Update
        </button>
      </div>
      <div className="d-flex items-center justify-between h-[7%]">
        <div className="d-flex items-center ">
          {isEditing ? (
            <input
              id="name"
              ref={accNameRef}
              type="text"
              value={accountData.name}
              onChange={handleChange}
              style={{
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "36px",
                color: "rgba(18, 20, 30, 1)",
                marginRight: "10px",
                outline: "none",
                borderBottom: "1.5px solid rgba(234, 234, 234, 1)",
                transition: "width 0.1s",
                width: "200px",
              }}
              className="capitalize"
              onFocus={(e) => {
                e.target.select();
              }}
            />
          ) : (
            <div
              className="h-[36px] text-align-center me-[10px] capitalize"
              style={{
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "36px",
                color: "rgba(18, 20, 30, 1)",
              }}
            >
              {accountData.name}
            </div>
          )}
          <div className="d-flex h-[36px] items-center">
            <div>
              <GoDotFill />
            </div>
            {isEditing ? (
              <>
                <input
                  id="autoGenerateNo"
                  ref={accNumRef}
                  type="number"
                  value={accountData.autoGenerateNo}
                  onChange={handleChange}
                  style={{
                    width: "80px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    fontWeight: "400",
                    outline: "none",
                    borderBottom: "1.5px solid rgba(234, 234, 234, 1)",
                  }}
                />
                <button
                  onClick={handleSaveClick}
                  style={{
                    marginRight: "10px",
                    width: "45px",
                    height: "25px",
                    fontSize: "12px",
                    backgroundColor: "#E06F2C",
                    color: "#fff",
                    borderRadius: "4px",
                  }}
                >
                  Save
                </button>
                <button
                  onClick={handleCancelClick}
                  style={{
                    height: "25px",
                    width: "50px",
                    fontSize: "12px",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "4px",
                  }}
                >
                  Cancel
                </button>
                {errorMessage && (
                  <div style={{ color: "red", marginLeft: "10px" }}>
                    {errorMessage}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="d-flex">{accountData.autoGenerateNo}</div>
                <div>
                  <button
                    className="w-[24px] h-[24px]"
                    onClick={handleEditClick}
                  >
                    <img src={Edit_light} alt="edit" />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex items-center">
          <div className="d-flex items-center h-[36px] me-[30px]">
            <div style={boldStyle1}>Disable</div>
            <div className="d-flex h-100 items-center ms-[10px]">
              <Switch
                className={`${accountData.isDisabled ? "" : "custom-switch"}`}
                onChange={onChangeDisable}
                checked={accountData.isDisabled}
                style={{
                  backgroundColor: accountData.isDisabled
                    ? "rgba(224, 111, 44, 1)"
                    : "rgba(248, 248, 248, 1)",
                }}
              />
            </div>
          </div>

          <div className="d-flex items-center h-[40px] w-[300px] me-[17px]">
            <div
              style={boldStyle1}
              className="w-[200px] d-flex items-center justify-end"
            >
              Account Type
            </div>
            <div className="d-flex h-100 items-center ms-[10px] w-[200px]">
              <AccountDropdown
                value={accountData.accountType}
                items={accountTypeItems}
                setAccountType={(newType) =>
                  setAccountData({ ...accountData, accountType: newType })
                }
              />
            </div>
          </div>

          <div className="d-flex items-center h-[40px]">
            <div style={boldStyle1} className="me-3">
              {accountData.accountType === "Camper" ? "Bunk" : "Spending Limit"}
            </div>
            <div className="d-flex h-full items-center  w-[120px]">
              {accountData.accountType === "Camper" ? (
                <AccountDropdown
                  value={accountData.bunk}
                  items={bunkItems}
                  setAccountType={(newBunk) =>
                    setAccountData({ ...accountData, bunk: newBunk })
                  }
                />
              ) : (
                <div className="relative w-full h-full">
                  <div
                    className="absolute left-[16px] top-1/2 transform -translate-y-1/2"
                    style={{ color: "#9C9C9C", fontSize: "16px" }}
                  >
                    &#36;
                  </div>

                  <input
                    className="w-full h-full px-[18px] focus:outline-none pl-[32px]"
                    style={{ borderRadius: "5px", background: "#F8F8F8" }}
                    id="spendingLimits"
                    value={accountData.spendingLimits}
                    onChange={handleChange}
                    type="number"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[5%] mt-[20px]">
        <div className="d-flex h-100 w-[220px] justify-between">
          <div style={boldStyle2}>Balance</div>
          <div style={boldStyle2}>Notes</div>
        </div>
      </div>
      <div className="h-[5%]">
        <div className="d-flex h-100">
          <div className="d-flex justify-between items-center">
            <div
              className=" h-[30px] d-flex justify-center items-center me-[7px] px-[10px]"
              style={{ ...buttonStyle, minWidth: "60px" }}
            >
              {formatBalance(accountData.balance) || "$0.0"}
              {/* &#36;{`${accountData.balance || 0}`} */}
            </div>
            <button
              className="h-[30px] d-flex justify-center items-center me-[15px] px-[10px]"
              style={buttonStyle}
              onClick={handleShowAdd}
            >
              +
            </button>
          </div>
          <div
            className="d-flex items-center bg-gray-600 w-[100%] ms-[30px] ps-[20px]"
            style={{ borderRadius: "6px" }}
          >
            <input
              id="notes"
              value={accountData.notes || ""}
              className="w-[100%] bg-gray-600 focus:outline-none"
              style={{ fontSize: "15px" }}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="h-[70%] pt-[30px]">
        <div className="d-flex h-[100%]">
          <div className="d-flex flex-column h-100 w-[25%] me-[30px]">
            <div
              className="d-flex flex-column h-[90%] bg-gray-600 px-[25px] pt-[20px]"
              style={{ borderRadius: "18px" }}
            >
              <div className="font-bold text-6xl">Balance and History</div>
              <div className="h-100 mt-[20px] overflow-y-auto custom-scrollbar">
                <BalanceHistoryTable history={balanceHistory} />
              </div>
            </div>
            <div className="d-flex items-end justify-center h-[10%] delete-button">
              <button
                id="deleteAccountButton"
                style={{
                  borderRadius: "18px",
                  border: "1px solid #E06F2C",
                  color: "#E06F2C",
                }}
                onClick={toggleDeleteModal}
              >
                Delete Account
              </button>
            </div>
          </div>
          <div
            className="h-100 w-[75%] bg-gray-600"
            style={{ borderRadius: "18px" }}
          >
            <div className="d-flex flex-column h-100 w-100 px-[40px] py-[20px]">
              <div className="d-flex h-[30px] justify-between items-center">
                <div className="font-bold text-6xl">Orders</div>
                <div className="d-flex items-center">
                  <div className="d-flex items-center ms-[25px]">
                    <div className="font-medium text-mini text-gray-700 me-[8px]">
                      Orders
                    </div>
                    <div
                      className="d-flex justify-center items-center h-[20px] text-mini text-chocolate-700 px-[6px]"
                      style={{
                        border: "1px solid #E06F2C",
                        borderRadius: "5px",
                      }}
                    >
                      <span>{ordersData.length}</span>
                    </div>
                  </div>

                  <div className="d-flex items-center ms-[25px]">
                    <div className="font-medium text-mini text-gray-700 me-[8px]">
                      Items
                    </div>
                    <div
                      className="d-flex justify-center items-center h-[20px] text-mini text-chocolate-700 px-[6px]"
                      style={{
                        border: "1px solid #E06F2C",
                        borderRadius: "5px",
                      }}
                    >
                      {/* <span>{SumFunction(ordersData, "items")}</span> */}
                      <span>{accountData?.items || 0}</span>
                    </div>
                  </div>

                  <div className="d-flex items-center ms-[25px]">
                    <div className="font-medium text-mini text-gray-700 me-[8px]">
                      Sales
                    </div>
                    <div
                      className="d-flex justify-center items-center h-[20px] text-mini text-chocolate-700 px-[6px]"
                      style={{
                        border: "1px solid #E06F2C",
                        borderRadius: "5px",
                      }}
                    >
                      <span>
                        {/* ${SumFunction(ordersData, "sales").toFixed(2)} */}$
                        {parseFloat(accountData?.sales).toFixed(2) || 0}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex items-center ms-[25px]">
                    <div className="font-medium text-mini text-gray-700 me-[8px]">
                      Profit
                    </div>
                    <div
                      className="d-flex justify-center items-center h-[20px] text-mini text-chocolate-700 px-[6px]"
                      style={{
                        border: "1px solid #E06F2C",
                        borderRadius: "5px",
                      }}
                    >
                      <span>
                        {/* ${SumFunction(ordersData, "profits").toFixed(2)} */}
                        ${parseFloat(accountData?.profits).toFixed(2) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[85%] mt-[20px] overflow-y-auto custom-scrollbar">
                <OrdersTable
                  orders={ordersData}
                  accountId={accountId}
                  userId={user.id}
                  token={token}
                  onCancelOrder={handleCancelOrder}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddBalance
        show={showAdd}
        setShow={setShowAdd}
        handleClose={handleCloseAdd}
        handleShowRemove={handleShowRemove}
        handleAddBalance={handleAddBalance}
        name={accountData.name}
      />

      <RemoveBalance
        show={showRemove}
        setShow={setShowAdd}
        handleClose={handleCloseRemove}
        handleShowAdd={handleShowAdd}
        handleRemoveBalance={handleRemoveBalance}
        name={accountData.name}
      />

      <Modal
        isVisible={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        backgroundColor={"white"}
      >
        <DeleteContent
          handleClose={toggleDeleteModal}
          handleDelete={handleDelete}
          name={accountData.name}
          title={"account"}
          isLoading={isDeleteLoading}
        />
      </Modal>

      <LoadingSpinner isLoading={loading} />
    </div>
  );
}

export default AccountView;
