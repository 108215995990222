import React, { useState, useEffect } from "react";
import { Segmented } from "antd";
import { tabType } from "../../data/AccountsData";
import toast from "react-hot-toast";
import axios from "axios";
import { getSampleFilePaths, handleDownloadSample } from "../../utils";
import SampleTable from "../../components/Common/SampleTable";
import { getSampleData } from "../../data/SampleTemplateData";
import DownloadUploadButton from "../Common/DownloadUploadButton";

const BulkUploadModalcontent = (props) => {
  const [alignValue, setAlignValue] = useState("camper");
  const [checked, setChecked] = useState(true);
  const [file, setFile] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (!selectedFile.name.endsWith(".csv")) {
      toast.error("Please select a valid CSV file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result;
      const rows = csvData.split("\n");
      const headers = rows[0]
        .split(",")
        .map((header) => header.trim().toLowerCase());

      if (!checked && !headers.includes("id")) {
        toast.error(
          "Id column is required when not auto-generating account ids"
        );
        return;
      }

      let jsonAccounts;

      if (alignValue === "camper") {
        if (
          !headers.includes("name") ||
          !headers.includes("bunk") ||
          !headers.includes("balance") ||
          !headers.includes("method") ||
          !headers.includes("notes")
        ) {
          toast.error(
            "Invalid CSV format for camper. Please ensure the file has columns: Name, Bunk, Balance, Method, Notes"
          );
          return;
        }

        jsonAccounts = rows.slice(1).map((row) => {
          const values = row.split(",");
          return {
            name: values[headers.indexOf("name")],
            bunk: values[headers.indexOf("bunk")],
            balance: parseFloat(
              values[headers.indexOf("balance")].replace("$", "")
            ),
            method: values[headers.indexOf("method")],
            notes: values[headers.indexOf("notes")],
            accountNo: checked ? null : values[headers.indexOf("id")],
            accountType: "Camper",
          };
        });
      } else if (alignValue === "cost" || alignValue === "staff") {
        if (
          !headers.includes("name") ||
          !headers.includes("spending limit") ||
          !headers.includes("notes")
        ) {
          toast.error(
            "Invalid CSV format for cost/staff. Please ensure the file has columns: Name, Spending Limit, Notes"
          );
          return;
        }

        jsonAccounts = rows.slice(1).map((row) => {
          const values = row.split(",");
          return {
            name: values[headers.indexOf("name")],
            spendingLimit: parseFloat(
              values[headers.indexOf("spending limit")].replace("$", "")
            ),
            notes: values[headers.indexOf("notes")],
            accountNo: checked ? null : values[headers.indexOf("id")],
            accountType: alignValue === "cost" ? "Cost" : "Staff",
          };
        });
      } else {
        toast.error("Invalid account type selected");
        return;
      }

      if (!checked) {
        const missingAccountNo = jsonAccounts.some(
          (account) => !account.accountNo
        );
        if (missingAccountNo) {
          toast.error("Account id is required for all accounts");
          return;
        }
      }

      setFile(selectedFile);

      setAccounts(jsonAccounts);
    };

    reader.readAsText(selectedFile);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const handleUpload = async () => {
    if (!file || accounts.length === 0) {
      toast.error("Please select a file to upload");
      return;
    }

    try {
      toast.loading("Uploading accounts...");
      const payload = {
        userId: props.userId,
        accounts,
        autoGenerateNo: checked,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/bulkUpload`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      if (res && res.data && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.handleUpload(res.data.data);
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }

    props.handleClose();
  };

  return (
    <div className="flex flex-col px-4 py-3 gap-2 h-full mx-auto max-w-[926px]">
      <div className="flex justify-between items-center">
        <h5 className="text-[16px] font-bold leading-[24px] text-gray-900">
          Bulk Account Upload
        </h5>
      </div>

      <div className="flex flex-col gap-3 items-center">
        <div className="flex flex-col items-center gap-2 text-center">
          <p className="text-[14px] text-[#12141E]">
            Use the provided template for your upload. The first row must
            include column headers(e.g., Name, Email, etc.) as described below.
            Any blank cells will result in missing information for that account.
            Save the file as a CSV and select the account type you wish to
            upload.
          </p>
        </div>

        <div className="flex flex-col items-center justify-center ">
          <Segmented
            defaultValue="camper"
            value={alignValue}
            style={{
              marginBottom: 8,
            }}
            onChange={(value) => setAlignValue(value)}
            options={tabType.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            className="custom-segmented-tab bg-white text-[14px] font-normal leading-[21px]"
          />
        </div>

        <SampleTable data={getSampleData(alignValue, checked)} />

        <div className="flex flex-row items-center justify-center gap-[16px]">
          <input
            id="generateAccountId"
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
            className="cursor-pointer"
          />
          <label
            htmlFor="generateAccountId"
            className="font-medium text-[12px] sm:text-[14px] leading-[21px] text-center text-[#12141E] cursor-pointer"
          >
            Auto Generate Account ID’s
          </label>
        </div>

        <DownloadUploadButton
          handleDownload={() =>
            handleDownloadSample(
              getSampleFilePaths(alignValue, checked),
              `${alignValue.charAt(0).toUpperCase()}${alignValue.slice(
                1
              )} Sample.csv`
            )
          }
          title="Download"
          subTitle="Sample Template"
          file={file}
          handleFileChange={handleFileChange}
        />

        <div className="flex justify-center gap-[11px] mt-3">
          <button
            className="px-4 py-2 bg-[#E0E0E0] text-black rounded-md"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#E06F2C] text-white rounded-md"
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadModalcontent;
