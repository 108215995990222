export const sortByItems = [
  { label: "Price", value: "price" },
  { label: "Amount Sold", value: "sold" },
  { label: "Low Stock", value: "stock" },
  { label: "Profits", value: "profits" },
];

export const categoryItems = [
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
  { label: "Ice Cream", value: "ice cream", img: "https://s3-alpha-sig.figma.com/img/bf17/4608/e8644945a268e11b9f0682667ac4d3c1?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dx5Yll0PTG6gJDNCaYywGUkA-FsYEe~FY8BeSdYZ3DMKpoDLQn1Q7ZyboYacdSgJxOY~rMlpuA~y3FRW23v2lYZM9oeJLMdZCKEk4E~JYmEPGPwOEQsYWdbBDkPNWDMrA7BXya4HgTYnngZLJ8V4w0EZYCkt1t~dtZDoYibT-62gd5rVT~HVl2h31iE3aHAFSTFKUguCAApHqrWy6D36ShjE0sK42YS5M70CMwG7m1yFemaStGEKmTT9HOVXZGcqnMOnmg94AvcsmY-XM1VUmJms3vAwHEeima65xDGHx8osToe5IAcVGCVgi2h9LZOFz3C3F5AvElz30wU2E59vkA__" },
];


export const productTableData = [
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.60",
    price: "$3.29",
    stock: 600,
    sold: 2100,
    sales: "$6,907.00",
    profit: "$5,907.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.55",
    price: "$3.15",
    stock: 550,
    sold: 2000,
    sales: "$6,300.00",
    profit: "$5,200.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.65",
    price: "$3.49",
    stock: 480,
    sold: 1800,
    sales: "$6,282.00",
    profit: "$5,082.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.45",
    price: "$2.79",
    stock: 700,
    sold: 2200,
    sales: "$6,138.00",
    profit: "$5,238.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.70",
    price: "$3.59",
    stock: 450,
    sold: 1900,
    sales: "$6,821.00",
    profit: "$5,821.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.50",
    price: "$2.99",
    stock: 525,
    sold: 2034,
    sales: "$6,081.66",
    profit: "$5,084.66",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.55",
    price: "$3.15",
    stock: 550,
    sold: 2000,
    sales: "$6,300.00",
    profit: "$5,200.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.60",
    price: "$3.29",
    stock: 600,
    sold: 2100,
    sales: "$6,907.00",
    profit: "$5,907.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.65",
    price: "$3.49",
    stock: 480,
    sold: 1800,
    sales: "$6,282.00",
    profit: "$5,082.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.45",
    price: "$2.79",
    stock: 700,
    sold: 2200,
    sales: "$6,138.00",
    profit: "$5,238.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.70",
    price: "$3.59",
    stock: 450,
    sold: 1900,
    sales: "$6,821.00",
    profit: "$5,821.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.60",
    price: "$3.29",
    stock: 600,
    sold: 2100,
    sales: "$6,907.00",
    profit: "$5,907.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.55",
    price: "$3.15",
    stock: 550,
    sold: 2000,
    sales: "$6,300.00",
    profit: "$5,200.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.65",
    price: "$3.49",
    stock: 480,
    sold: 1800,
    sales: "$6,282.00",
    profit: "$5,082.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.45",
    price: "$2.79",
    stock: 700,
    sold: 2200,
    sales: "$6,138.00",
    profit: "$5,238.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.70",
    price: "$3.59",
    stock: 450,
    sold: 1900,
    sales: "$6,821.00",
    profit: "$5,821.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.60",
    price: "$3.29",
    stock: 600,
    sold: 2100,
    sales: "$6,907.00",
    profit: "$5,907.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.55",
    price: "$3.15",
    stock: 550,
    sold: 2000,
    sales: "$6,300.00",
    profit: "$5,200.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.65",
    price: "$3.49",
    stock: 480,
    sold: 1800,
    sales: "$6,282.00",
    profit: "$5,082.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.45",
    price: "$2.79",
    stock: 700,
    sold: 2200,
    sales: "$6,138.00",
    profit: "$5,238.00",
    details: "View Full",
  },
  {
    product: "Soft Serve",
    category: "Ice cream",
    cost: "$0.70",
    price: "$3.59",
    stock: 450,
    sold: 1900,
    sales: "$6,821.00",
    profit: "$5,821.00",
    details: "View Full",
  },
];
