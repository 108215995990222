import sortUp from "../../assets/SortUp.svg";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropdownList from "./dropdownList";
import { sortByItems } from "../../data/ProductTableData";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { getCategoryNameById } from "../../utils";
import { AuthContext } from "../../auth/AuthProvider";
import axios from "axios";

const ItemTypes = {
  ROW: "row",
};

const DraggableRow = ({
  index,
  moveRow,
  data,
  columns,
  handleViewFull,
  boldStyle,
  normalStyle,
  stockStyle,
  sortStyle,
  categories,
  updateProductPositions,
  isLastRowIndex,
}) => {
  const ref = useRef(null);
  const dragRef = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.ROW,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex, item.productId, data.productId);

      item.index = hoverIndex;
      item.hoverId = data.productId;
    },
    drop(item) {
      updateProductPositions(item.productId, item.hoverId);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: { index, productId: data.productId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragRef);
  drop(ref);

  const removeBottomBorder = {
    borderBottom: isLastRowIndex ? "none" : "1px solid #ddd",
  };

  return (
    <tr ref={ref} style={{ ...normalStyle, opacity: isDragging ? 0.5 : 1 }}>
      <td style={{ ...removeBottomBorder }}>
        <div className="d-flex justify-center items-center">
          <button
            ref={dragRef}
            style={{ cursor: "grab", border: "none", background: "none" }}
          >
            <img src={sortUp} alt="=" width="30px" height="30px" />
          </button>
        </div>
      </td>
      <td style={{ ...removeBottomBorder }}>{data.productName}</td>
      <td style={{ ...removeBottomBorder }}>
        {getCategoryNameById(data.categoryId, categories) || "Not defined"}
      </td>
      <td style={{ ...removeBottomBorder }}>{`$${data.cost}`}</td>
      <td style={{ ...removeBottomBorder }}>{`$${data.price}`}</td>
      <td style={{ ...removeBottomBorder }}>
        <div className="inline-block min-w-[50px]" style={stockStyle}>
          {data.stock}
        </div>
      </td>
      <td style={{ ...removeBottomBorder }}>{data.sold || 0}</td>
      <td style={{ ...removeBottomBorder }}>{`$${data.sales || 0}`}</td>
      <td style={{ ...removeBottomBorder }}>{`$${data.profits || 0}`}</td>
      <td style={{ ...removeBottomBorder }}>
        <div
          className="hover:cursor-pointer inline-block"
          onClick={() => handleViewFull(data.productId)}
          style={sortStyle}
        >
          View Full
        </div>
      </td>
    </tr>
  );
};

const ProductsTable = ({
  categories,
  selectedCategoryId,
  data,
  setData,
  suggestions,
  query,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { user, token } = useContext(AuthContext) || {};

  const [order, setOrder] = useState("ASC");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const updateProductPositions = async (dragProductId, dropProductId) => {
    try {
      const payload = { userId: user.id, dragProductId, dropProductId };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/drag`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating product positions:", error);
    }
  };
  const sorting = (col) => {
    const sorted = [...data].sort((a, b) => {
      const aValue = col === "profits" ? parseFloat(a[col]) : a[col];
      const bValue = col === "profits" ? parseFloat(b[col]) : b[col];
      return order === "ASC"
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
        ? 1
        : -1;
    });
    setData(sorted);
    setOrder(order === "ASC" ? "DSC" : "ASC");
  };

  const handleSortByChange = (option) => {
    sorting(option);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRow = data[dragIndex];
    const newData = [...data];
    newData.splice(dragIndex, 1);
    newData.splice(hoverIndex, 0, dragRow);
    setData(newData);
  };

  const columns = [
    "",
    "Product",
    "Category",
    "Cost",
    "Price",
    "Stock",
    "Sold",
    "Sales",
    "Profit",
    "Sort By",
  ];

  const handleViewFull = (productId) => {
    navigate(`/products/product-details?productId=${productId}`);
  };

  useEffect(() => {
    let filteredData = selectedCategoryId
      ? data.filter((product) => product.categoryId === selectedCategoryId)
      : data;

    setFilteredProducts(filteredData);
  }, [selectedCategoryId, data]);

  const boldStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    lineHeight: "24.75px",
    color: "rgba(18, 20, 30, 1)",
    backgroundColor: "#F8F8F8",
  };

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(18, 20, 30, 1)",
    height: "58px",
    verticalAlign: "middle",
  };

  const stockStyle = {
    borderRadius: "6px",
    backgroundColor: "#E06F2C",
    color: "#FFFFFF",
    padding: "2px 10px",
    border: "1.5px solid",
    gap: "10px",
  };

  const sortStyle = {
    color: "#000000",
    lineHeight: "21px",
    borderRadius: "6px",
    border: "1.5px solid",
    borderColor: "#EAEAEA",
    padding: "2px 12px",
    gap: "10px",
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <table className="table">
        <colgroup>
          <col style={{ width: "1%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "7%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
        </colgroup>
        <thead>
          <tr
            style={{ position: "sticky", top: "0px", verticalAlign: "middle" }}
          >
            {columns.map((column, index) => (
              <th
                className="ps-[10px]"
                key={index}
                style={{
                  ...boldStyle,
                  borderRadius:
                    index === 0
                      ? "10px 0 0 0"
                      : index === columns.length - 1
                      ? "0 10px 0 0"
                      : "0",
                  paddingLeft: index === 0 ? "24px" : "8px",
                }}
              >
                {index === 9 ? (
                  <DropdownList
                    items={sortByItems}
                    style={boldStyle}
                    onSelect={handleSortByChange}
                    order={order}
                  />
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <div className="absolute top-[60%] left-[47%]">
              <Spinner size="xl" color="#FF8943 !important" />
            </div>
          ) : (
            (query.length !== 0 ? suggestions : filteredProducts).map(
              (row, rowIndex) => {
                const isLastRowIndex = rowIndex === data.length - 1;
                return (
                  <DraggableRow
                    key={row.productId}
                    index={rowIndex}
                    moveRow={moveRow}
                    data={row}
                    columns={columns}
                    handleViewFull={handleViewFull}
                    boldStyle={boldStyle}
                    normalStyle={normalStyle}
                    stockStyle={stockStyle}
                    sortStyle={sortStyle}
                    categories={categories}
                    updateProductPositions={updateProductPositions}
                    isLastRowIndex={isLastRowIndex}
                  />
                );
              }
            )
          )}
        </tbody>
      </table>
    </DndProvider>
  );
};

export default ProductsTable;
