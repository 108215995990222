import React, { useState } from "react";
import OrderPie from "../GridComponents/OrderPie";
import SalesandProfit from "../GridComponents/SalesandProfit";
import TopProducts from "../GridComponents/TopProducts";
import LifeTime from "../GridComponents/LifeTime";
import MonthView from "../GridComponents/MonthView";

function GridLayout() {
  const [monthView, setMonthView] = useState(false);

  const handleShowMonthData = () => {
    setMonthView(true);
  };

  const handleBackToGrid = () => {
    setMonthView(false);
  };

  return (
    <div className="w-full h-full overflow-y-auto hide-scrollbar">
      {monthView ? (
        <MonthView onBack={handleBackToGrid} />
      ) : (
        <div className="w-full h-full grid grid-cols-1 md:grid-cols-12 gap-2 md:gap-4">
          <div className="bg-white rounded-lg p-2 md:col-span-6">
            <OrderPie />
          </div>
          <div
            className="bg-white rounded-lg p-2 md:col-span-6 cursor-pointer "
            onClick={handleShowMonthData}
          >
            <SalesandProfit />
          </div>
          <div className="bg-white rounded-lg p-2 md:col-span-9">
            <TopProducts />
          </div>
          <div className="bg-white rounded-lg p-2 md:col-span-3">
            <LifeTime />
          </div>
        </div>
      )}
    </div>
  );
}

export default GridLayout;
