import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import { Toaster } from "react-hot-toast";
import GlobalDndContext from "./components/DndContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalDndContext>
    <BrowserRouter>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Toaster />
            <App />
          </AuthProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </BrowserRouter>
  </GlobalDndContext>
);

reportWebVitals();
