import React from "react";

function StatusChip({ status }) {
  return (
    <div
      className={`rounded ${
        status === "Approved" ? "bg-[#BCEACD]" : "bg-[#FEC8C8]"
      } flex justify-center me-6 py-1`}
    >
      {status === "Approved" ? (
        <span className="text-[#39A130] text-sm">Approved</span>
      ) : (
        <span className="text-[#C0272D] text-sm">Declined</span>
      )}
    </div>
  );
}

export default StatusChip;
