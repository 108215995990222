import React from "react";

const CheckboxComponent = ({name, checked, onChange}) => {
  return (
    <div className="flex flex-row gap-[8px]">
      <input
        type="checkbox"
        defaultChecked={checked}
        checked={checked}
        onChange={() => onChange(name, !checked)}
      />

      <div className="font-medium text-[16.5px] leading-[24.75px] text-center text-[#12141E]">
        {name}
      </div>
    </div>
  );
};

export default CheckboxComponent;
