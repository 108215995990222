import React, { useContext, useEffect, useState } from "react";
import Cross from "../../../assets/Cross.svg";
import CategoryItem from "./CategoryItem";
import CircleSelection from "../../Common/CircleSelection";
import { pdf } from "@react-pdf/renderer";
import MyDocument from "./MenuPdf";
import { AuthContext } from "../../../auth/AuthProvider";

const MenuContent = (props) => {
  const { user } = useContext(AuthContext);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedForDownload, setSelectedForDownload] = useState(
    props.categories.map((category) => category.categoryId)
  );
  const [selectedProducts, setSelectedProducts] = useState(
    props.products.map((product) => product.productId)
  );

  useEffect(() => {
    if (props.categories.length > 0) {
      setSelectedCategoryId(props.categories[0].categoryId);
    }
  }, [props.categories]);

  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const handleProductSelect = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId]
    );
  };

  const handleDownloadSelect = (categoryId) => {
    setSelectedForDownload((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId]
    );
  };

  const handleGenerate = () => {
    const downloadContent = props.categories
      .filter((category) => selectedForDownload.includes(category.categoryId))
      .map((category) => {
        const categoryProducts = props.products.filter(
          (product) =>
            product.categoryId === category.categoryId &&
            selectedProducts.includes(product.productId)
        );

        const imageUrl = category.categoryImgUrl;
        const decodedPath = decodeURIComponent(new URL(imageUrl).pathname);
        const imageName = decodedPath.split("/").pop();

        const localImagePath = require(`../../../assets/categoryImages/${imageName}`);
        return {
          categoryImage: localImagePath,
          categoryName: category.categoryName,
          products: categoryProducts.map((product) => ({
            name: product.productName,
            cost: product.cost,
          })),
        };
      })
      .filter((category) => category.products.length > 0);

    pdf(
      <MyDocument
        categoryData={downloadContent}
        title={user.title}
        subTitle={user.subTitle}
      />
    )
      .toBlob()
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${user.title}_menu.pdf`;
        link.click();
      });
  };

  return (
    <div className="p-[20px] flex flex-col gap-[25px]">
      <div className="flex flex-row justify-between">
        <div
          className="font-bold"
          style={{ fontSize: "16px", lineHeight: "24px", color: "#12141E" }}
        >
          Generate Menu
        </div>

        <button className="w-[15.5px] h-[15.5px]" onClick={props.handleClose}>
          <img src={Cross} alt="cross" />
        </button>
      </div>

      <div className="flex flex-row gap-[22px] w-full overflow-auto hide-scrollbar mask px-4">
        {props.categories.map((category, index) => (
          <CategoryItem
            key={index}
            imageUrl={category.categoryBlobUrl}
            name={category.categoryName}
            categoryId={category.categoryId}
            onCategoryClick={() => handleCategorySelect(category.categoryId)}
            onDownloadSelect={() => handleDownloadSelect(category.categoryId)}
            isSelectedForCategory={selectedCategoryId === category.categoryId}
            isSelectedForDownload={selectedForDownload.includes(
              category.categoryId
            )}
          />
        ))}
      </div>

      {selectedCategoryId ? (
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center mx-auto h-[200px] overflow-auto custom-scrollbar">
          {props.products
            .filter((product) => product.categoryId === selectedCategoryId)
            .map((product, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row gap-4"
                  style={{
                    width: "fit-content",
                  }}
                >
                  <CircleSelection
                    size="32"
                    isSelected={selectedProducts.includes(product.productId)}
                    onClick={() => handleProductSelect(product.productId)}
                  />
                  <span className="capitalize overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px]">
                    {product.productName}
                  </span>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          className="mt-4"
          style={{
            textAlign: "center",
          }}
        >
          No categories selected.
        </div>
      )}

      <div className="d-flex justify-center items-center gap-[11px] my-3">
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: "#E0E0E0",
            borderRadius: "5px",
            color: "#000000",
            cursor: "pointer",
          }}
          onClick={props.handleClose}
        >
          Cancel
        </button>
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: "#E06F2C",
            borderRadius: "5px",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={handleGenerate}
        >
          Generate
        </button>
      </div>
    </div>
  );
};

export default MenuContent;
