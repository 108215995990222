import React, { useCallback, useEffect, useState } from "react";

const SelectableGrid = ({ cols = 1, items = [], children }) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (cols > 0) {
      const itemCount = items?.length;
      const calculateRows = Math.ceil(itemCount / cols);
      setRows(calculateRows);
    } else {
      setRows(1);
    }
  }, [cols, items.length]);

  const toggleSelected = useCallback((index) => {
    setSelectedBox((prevSelected) => (prevSelected === index ? null : index));
  }, []);

  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        rowGap: "10px",
        columnGap: "10px",
      }}
    >
      {[...Array(rows * cols).keys()].map((index) => {
        if (index < items?.length) {
          return (
            <div key={index}>
              {children({
                onClick: () => toggleSelected(index),
                isSelected: selectedBox === index,
                item: items[index],
              })}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default SelectableGrid;
