import React from "react";

const CategoryButton = ({
  categoryName,
  imageUrl,
  onClick,
  isSelected,
}) => {
  return (
    <div
      className={`w-[109px] h-[132px] flex-shrink-0 ${
        isSelected ? "text-sienna" : ""
      }`}
      style={{ marginRight: "15px" }}
      onClick={onClick}
    >
      <div
        className={`rounded-lg border-[1px] border-solid w-full h-full cursor-pointer d-flex flex-col justify-center items-center ${
          isSelected
            ? "bg-chocolate-300 border-[#E66E27]"
            : "bg-white border-lightgray-100"
        }`}
      >
        <div className="h-[60%] w-full d-flex items-end justify-center">
          <img
            className="w-[51px] h-[51px]"
            alt={categoryName}
            src={imageUrl}
          />
        </div>

        <div className="h-[40%] w-full d-flex  justify-center py-[6px]">
          <div
            className={`text-center font-nunito w-[80px]  ${
              isSelected ? "text-sienna" : "text-gray-500"
            }`}
            style={{
              lineHeight: "20.46px",
              fontSize: "15px",
              color: isSelected ? "#704332" : "#A69D9A",
              fontWeight: "600",
            }}
          >
            {categoryName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryButton;
