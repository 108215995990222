import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import home from "../assets/home.svg";
import page from "../assets/page.svg";
import dashboard from "../assets/dashboard.svg";
import wallet from "../assets/wallet.svg";
import setting from "../assets/setting.svg";
import selectedHome from "../assets/SelectedHome.svg";
import selectedPage from "../assets/SelectedPage.svg";
import selectedDashboard from "../assets/SelectedDashboard.svg";
import selectedWallet from "../assets/SelectedWallet.svg";
import selectedSetting from "../assets/SelectedSetting.svg";
import LayoutItem from "./LayoutItem";
import logo from "../assets/logo.png";
import signout from "../assets/Sign_out_squre.svg";
import LogoutModal from "../components/LogoutModal";
import transaction from "../assets/transaction.svg";
import selectedTransaction from "../assets/SelectedTransaction.svg";

const LeftLayout = ({ onClose }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isSelected, setIsSelected] = useState("Dashboard");
  const location = useLocation();

  const menuItems = [
    {
      name: "Dashboard",
      path: "/dashboard",
      defaultIcon: home,
      selectedIcon: selectedHome,
    },
    {
      name: "Products",
      path: "/products",
      defaultIcon: page,
      selectedIcon: selectedPage,
    },
    {
      name: "Orders",
      path: "/orders",
      defaultIcon: dashboard,
      selectedIcon: selectedDashboard,
    },
    {
      name: "Accounts",
      path: "/accounts",
      defaultIcon: wallet,
      selectedIcon: selectedWallet,
    },
    {
      name: "Transactions",
      path: "/transactions",
      defaultIcon: transaction,
      selectedIcon: selectedTransaction,
    },
    {
      name: "Setting",
      path: "/setting/general-settings",
      defaultIcon: setting,
      selectedIcon: selectedSetting,
    },
  ];

  useEffect(() => {
    const selectedItem = menuItems.find((item) =>
      location.pathname.split("/")[1].includes(item.name.toLowerCase())
    );

    if (selectedItem) {
      setIsSelected(selectedItem.name);
    }
  }, [location.pathname, menuItems]);

  const toggleLogoutModal = (isOpen) => {
    setIsLogoutModalOpen(isOpen);
  };

  return (
    <>
      <div className="flex flex-col items-center w-[100px] h-full bg-white">
        <div
          className="w-[58px] h-[58px] flex items-center justify-center mt-[35px]"
          style={{
            backgroundColor: "#FFEDE2",
            borderRadius: "18px",
          }}
        >
          <img src={logo} alt="logo" className="p-2" />
        </div>

        <div className="mobile-landscape:mt-[50px] mt-[90px]">
          <nav className="flex flex-col mobile-landscape:gap-[25px] gap-[50px] items-center w-full max-h-[calc(100vh-15rem)] overflow-y-auto custom-scrollbar">
            {menuItems.map((item) => (
              <LayoutItem
                key={item.name}
                name={item.name}
                defaultIcon={item.defaultIcon}
                SelectedIcon={item.selectedIcon}
                isSelected={isSelected}
                path={item.path}
                onClick={onClose}
              />
            ))}
          </nav>
        </div>

        <div className="flex-grow flex items-end mobile-portrait:mb-[70px] mb-[35px]">
          <img
            src={signout}
            alt="Log out"
            className="cursor-pointer"
            onClick={() => toggleLogoutModal(true)}
          />
        </div>
      </div>

      <LogoutModal
        show={isLogoutModalOpen}
        handleClose={() => toggleLogoutModal(false)}
      />
    </>
  );
};

export default LeftLayout;
