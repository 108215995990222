import React, { useState, useContext, useEffect } from "react";
import { Switch } from "antd";
import { AuthContext } from "../../../auth/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import FloatingInputField from "../FloatingInputField";

function GeneralSettings() {
  const { user, token, setUser } = useContext(AuthContext);
  const [checkedPrint, setCheckedPrint] = useState(user.isAddBalanceEnabled);
  const [cashCheckout, setCashCheckout] = useState(user?.isCashCheckoutEnabled);
  const [canteenName, setCanteenName] = useState(user?.canteenName || "");
  const [campName, setCampName] = useState(user?.campName || "");

  const onChangePrint = (checked) => {
    setCheckedPrint(checked);
    toggleBalanceSetting();
  };

  const toggleBalanceSetting = async () => {
    try {
      const payload = { userId: user.id, isAddBalanceEnabled: !checkedPrint };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/setting/enableBalance`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        setUser({
          ...user,
          isAddBalanceEnabled: res.data.data.isAddBalanceEnabled,
        });
      }
    } catch (error) {
      toast.error("Failed to update balance setting");
    }
  };

  const onChangeCashCheckout = (checked) => {
    setCashCheckout(checked);
    toggleCashCheckout();
  };

  const toggleCashCheckout = async () => {
    try {
      const payload = { userId: user.id, isCashCheckoutEnabled: !cashCheckout };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/setting/cashCheckout`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        setUser({
          ...user,
          isCashCheckoutEnabled: res.data.data.isCashCheckoutEnabled,
        });
      }
    } catch (error) {
      toast.error("Failed to update cash checkout setting");
    }
  };

  const saveGeneralSettings = async () => {
    try {
      const payload = {
        userId: user.id,
        canteenName,
        campName,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/setting/general`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        setUser({
          ...user,
          canteenName: res.data.data.canteenName,
          campName: res.data.data.campName,
        });
      }
    } catch (error) {
      toast.error("Failed to save general settings");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      saveGeneralSettings();
    }, 2000);

    return () => clearTimeout(timer);
  }, [canteenName, campName]);

  return (
    <div className="flex flex-col items-center px-[55px] py-[30px] gap-[37px]">
      <div className="flex gap-[23px] w-full">
        <FloatingInputField
          id="canteenName"
          label="Canteen Name"
          value={canteenName}
          onChange={(e) => setCanteenName(e.target.value)}
        />

        <FloatingInputField
          id="campName"
          label="Camp Name"
          value={campName}
          onChange={(e) => setCampName(e.target.value)}
        />
      </div>

      <div className="d-flex items-center w-[100%]">
        <div className="d-flex flex-row justify-between w-full">
          <div
            className="font-medium w-[80%] leading-[28px]"
            style={{ fontSize: "18px" }}
          >
            Add Balance to accounts from kiosk -{" "}
            <span style={{ fontSize: "16px" }}>
              when this is on it will allow the kiosk operator to add balances
              to accounts from his kiosk. this will only apply to camper
              accounts
            </span>
          </div>
          <div>
            <Switch
              className={`${checkedPrint ? "" : "custom-switch"}`}
              onChange={onChangePrint}
              checked={checkedPrint}
              style={{
                backgroundColor: checkedPrint
                  ? "rgba(224, 111, 44, 1)"
                  : "rgba(248, 248, 248, 1)",
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex items-center w-full">
        <div className="d-flex flex-row justify-between w-[100%]">
          <div
            className="font-medium w-[80%] leading-[28px]"
            style={{ fontSize: "18px" }}
          >
            Cash Checkout without an account -{" "}
            <span style={{ fontSize: "16px" }}>
              when this is on it will allow the kiosk operator to place orders
              of cash without an account entry
            </span>
          </div>
          <div>
            <Switch
              className={`${cashCheckout ? "" : "custom-switch"}`}
              onChange={onChangeCashCheckout}
              checked={cashCheckout}
              style={{
                backgroundColor: cashCheckout
                  ? "rgba(224, 111, 44, 1)"
                  : "rgba(248, 248, 248, 1)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
