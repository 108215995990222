import React, { useContext, useRef, useState, useEffect } from "react";
import backArrow from "../../assets/backArrow.svg";
import ProductEditTable from "./ProductEditTable";
import RecentOrdersTable from "./RecentOrdersTable";
import Trash from "../../assets/Trash.svg";
import img_empty from "../../assets/img_empty.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../auth/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "../../db/firebase";
import { v4 as uuidv4 } from "uuid";
import Modal from "../Common/modal";
import DeleteContent from "../Common/deleteContent";
import LoadingSpinner from "../Common/loadingSpinner";
import { Spinner } from "react-bootstrap";

function ProductDetails() {
  const [img, setImg] = useState(null);
  const imgRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("productId");
  const { user, token, accounts, setProductsData } = useContext(AuthContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentOrderData, setRecentOrderData] = useState([]);

  const [data, setData] = useState({
    userId: user.id,
    productName: "",
    categoryId: "",
    cost: 0.0,
    price: 0.0,
    stock: 0,
    productImgUrl: "",
    sold: 0,
    sales: 0,
    profits: 0,
    isPrinted: false,
    isDisabled: false,
    addOns: [],
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const validateForm = () => {
    const { productName, productImgUrl, cost, price, stock, categoryId } = data;
    if (productId) {
      return true;
    } else if (
      productName &&
      cost &&
      price &&
      stock &&
      categoryId &&
      !productId
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsButtonDisabled(!validateForm());
  }, [data]);

  const fetchProductData = async () => {
    try {
      setIsLoading(true);
      const payload = { userId: user.id, productId: productId };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/getByPID`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const productData = response.data.data;

      setData({
        ...data,
        ...productData,
      });
    } catch (error) {
      console.error("Failed to fetch user data", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  const fetchRecentOrderData = async () => {
    try {
      const payload = { userId: user.id, productId: productId };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/getRecentOrders`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRecentOrderData(response.data.data);
    } catch (error) {
      console.error("Failed to fetch Recent Order data", error);
    }
  };
  useEffect(() => {
    if (productId) {
      fetchRecentOrderData();
    }
  }, [productId]);

  const handleUploadClick = () => {
    imgRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setData({
        ...data,
        productImgUrl: URL.createObjectURL(file),
      });
      setImg(file);
    } else {
      setData({
        ...data,
        productImgUrl: "",
      });
      setImg(null);
    }
  };

  const handleDeleteImage = () => {
    setData({
      ...data,
      productImgUrl: null,
    });
    setImg(null);
  };

  const handleBackButton = () => {
    navigate("/products");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      toast.loading("Please wait...");
      let imageUrl = data.productImgUrl;
      if (img) {
        const storageRef = ref(storage, `products/${uuidv4()}_${img.name}`);
        const snapshot = await uploadBytes(storageRef, img);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const payload = { ...data, productImgUrl: imageUrl };
      let res;
      if (productId) {
        res = await axios.put(
          `${process.env.REACT_APP_HOST_URL}/api/pos/product/update`,
          { ...payload, productId: productId },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await axios.post(
          `${process.env.REACT_APP_HOST_URL}/api/pos/product/create`,
          payload,
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        setProductsData((prevData) => {
          if (productId) {
            return prevData.map((product) =>
              product.productId === productId
                ? { ...product, ...payload }
                : product
            );
          } else {
            return [...prevData, res.data.data];
          }
        });
        navigate("/products");
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };

  const handleDeleteImageFromStorage = async () => {
    if (data.productImgUrl) {
      const imageRef = ref(storage, data.productImgUrl);
      try {
        await deleteObject(imageRef);
      } catch (error) {
        toast.error("Failed to delete image");
      }
    }
  };

  const handleDelete = async () => {
    try {
      toast.loading("Please wait...");
      await handleDeleteImageFromStorage();
      const res = await axios.delete(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/delete`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          data: { userId: user.id, productId: productId },
        }
      );
      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data && res.data.message);
        setProductsData((prevData) =>
          prevData.filter((product) => product.productId !== productId)
        );
        navigate("/products");
      } else {
        toast.dismiss();
        toast.error(res.data && res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.message);
    }
  };


  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return isLoading ? (
    <div className="d-flex justify-center items-center h-[100%] w-[100%]">
      <Spinner />
    </div>
  ) : (
    <>
      <div
        className="container-fluid d-flex flex-column h-100 bg-white "
        style={{ borderRadius: "20px" }}
      >
        <div className="d-flex items-center items h-[9%]">
          <div className="d-flex items-center flex-grow-1">
            <button
              className=" d-flex items-center w-[60px] h-[21px] ml-[30px] mt-[20px] transition duration-300 ease-in-out hover:bg-gray-500"
              style={{ borderRadius: "4px" }}
              onClick={handleBackButton}
            >
              <img src={backArrow} width="12px" height="13.4px" alt="arrow" />
              <div
                className="w-[35px] h-[21px] text-align-center flex-grow-1"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Back
              </div>
            </button>
          </div>
          {productId && (
            <button
              className="btn mt-[20px] me-[20px]"
              style={{
                color: "rgba(224, 111, 44, 1)",
                border: "1px solid #E06F2C",
              }}
              onClick={toggleDeleteModal}
            >
              Delete Product
            </button>
          )}
          <button
            className="btn w-[107px] h-[40px] me-[20px] mt-[20px]"
            style={{
              backgroundColor: "rgba(224, 111, 44, 1)",
              color: "#fff",
            }}
            onClick={handleSave}
            disabled={isButtonDisabled}
          >
            {productId ? "Update" : "Save"}
          </button>
        </div>

        <div className="d-flex h-[42%] ">
          <div
            className="relative w-[240px] h-[95%] ml-[6%] mt-[10px] "
            style={{
              borderRadius: "16px",
              border: "1px solid rgba(205, 203, 202, 1)",
              maxHeight: "390px",
              background:
                "linear-gradient(220.89deg, rgba(255, 255, 255, 0) 75.89%, #BABABA 95.58%)",
            }}
          >
            {data?.productImgUrl ? (
              <>
                <img
                  className="h-[100%] w-[100%] p-[4px]"
                  style={{ borderRadius: "16px", objectFit: "contain" }}
                  src={data.productImgUrl}
                  alt="img"
                />

                <button
                  className="absolute top-[83%] left-[8%] w-[32px] h-[32px] "
                  onClick={() => handleDeleteImage()}
                >
                  <FaRegTrashAlt color="white" size={24} />
                </button>
              </>
            ) : (
              <div
                className="d-flex flex-column items-center justify-center h-[100%] w-[100%] hover:cursor-pointer gap-[20.67px]"
                onClick={handleUploadClick}
              >
                <div>
                  <img src={img_empty} alt="Empty" />
                  <input
                    type="file"
                    ref={imgRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                </div>

                <div
                  style={{
                    height: "44px",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "14.5px",
                    lineHeight: "21.75px",
                    color: "rgba(162, 162, 162, 1)",
                  }}
                >
                  Upload Product <br /> Image
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-center items-center w-[50%] h-100 me-[1%]"
            style={{ maxWidth: "" }}
          >
            <ProductEditTable data={data} setData={setData} />
          </div>
          <div className="d-flex flex-column justify-evenly w-[16%] h-100">
            <div className="d-flex flex-column mt-[14%]">
              <div
                className="d-flex items-center w-[100px] h-[32px]"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "32px",
                  color: "rgba(0, 0, 0, 0.3)",
                }}
              >
                Sold
              </div>
              <div
                className="w-[100%] h-[50px] mt-[-5px]"
                style={{
                  fontWeight: "400",
                  fontSize: "30px",
                  lineHeight: "45px",
                  color: "rgba(224, 111, 44, 1)",
                }}
              >
                {data.sold}
              </div>
            </div>

            <div className="d-flex flex-column mt-[5px]">
              <div
                className="d-flex items-center w-[100%] h-[32px]"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "32px",
                  color: "rgba(0, 0, 0, 0.3)",
                }}
              >
                Sales
              </div>
              <div
                className="w-[100%] h-[50px] mt-[-5px]"
                style={{
                  fontWeight: "400",
                  fontSize: "30px",
                  lineHeight: "45px",
                  color: "rgba(224, 111, 44, 1)",
                }}
              >
                ${data.sales}
              </div>
            </div>

            <div className="d-flex flex-column mt-[5px]">
              <div
                className="d-flex items-center w-[100%] h-[32px]"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "32px",
                  color: "rgba(0, 0, 0, 0.3)",
                }}
              >
                Profit
              </div>
              <div
                className="w-[100%] h-[50px] mt-[-5px] "
                style={{
                  fontWeight: "400",
                  fontSize: "30px",
                  lineHeight: "45px",
                  color: "rgba(224, 111, 44, 1)",
                }}
              >
                ${data.profits}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column items-center justify-center h-[49%]">
          <div
            className="w-[88%] my-[10px]"
            style={{
              fontWeight: "700",
              fontSize: "19px",
              lineHeight: "28.5px",
              color: " rgba(18, 20, 30, 1)",
            }}
          >
            Recent Orders
          </div>
          <div
            className="d-flex justify-center h-100 w-[88%] overflow-y-auto custom-scrollbar"
            style={{ paddingLeft: "2px", border: "10px" }}
          >
            <RecentOrdersTable
              orders={recentOrderData}
              accounts={accounts}
              setOrders={setRecentOrderData}
            />
          </div>
        </div>
      </div>

      <Modal
        isVisible={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        backgroundColor={"white"}
      >
        <DeleteContent
          handleClose={toggleDeleteModal}
          handleDelete={handleDelete}
          name={data.productName}
          title={"product"}
        />
      </Modal>

      <LoadingSpinner isLoading={isLoading} />
    </>
  );
}

export default ProductDetails;
