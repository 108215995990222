export const sampleProductData = [
  {
    productName: "Cool Pop",
    category: "Ice Cream",
    cost: "$" + (0.5).toFixed(2),
    price: "$" + (1.0).toFixed(2),
    stock: 380,
  },
  {
    productName: "Diet Coke",
    category: "Drinks",
    cost: "$" + (0.33).toFixed(2),
    price: "$" + (1.25).toFixed(2),
    stock: 1440,
  },
  {
    productName: "Slush",
    category: "Ice Cream",
    cost: "$" + (0.8).toFixed(2),
    price: "$" + (1.5).toFixed(2),
    stock: 2500,
  },
  {
    productName: "Hot Dog",
    category: "Meat",
    cost: "$" + (1.49).toFixed(2),
    price: "$" + (3.0).toFixed(2),
    stock: 400,
  },
  {
    productName: "Pizza Wrap",
    category: "Pizza",
    cost: "$" + (1.65).toFixed(2),
    price: "$" + (3.25).toFixed(2),
    stock: 225,
  },
];

export const sampleCamperWithoutId = [
  {
    name: "Samy Cohen",
    bunk: "21",
    balance: "$" + (90).toFixed(2),
    method: "Cash",
    notes: "Paid through Tuition USD",
  },
  {
    name: "Levi Goldberg",
    bunk: "2",
    balance: "$" + (75).toFixed(2),
    method: "Cash",
    notes: "Gave cash to canteen manager",
  },
  {
    name: "Yossi Zaks",
    bunk: "1a",
    balance: "$" + (120).toFixed(2),
    method: "Cash",
    notes: "Bunk list first day",
  },
  {
    name: "Izzy Fine",
    bunk: "16",
    balance: "$" + (40).toFixed(2),
    method: "Website",
    notes: "",
  },
  {
    name: "Mendel Cohen",
    bunk: "16",
    balance: "$" + (80).toFixed(2),
    method: "Cash",
    notes: "Paid through Tuition CAD",
  },
];

export const sampleCamperWithId = [
  {
    name: "Samy Cohen",
    bunk: "21",
    balance: "$" + (90).toFixed(2),
    method: "Cash",
    id: 1001,
    notes: "Paid through Tuition USD",
  },
  {
    name: "Levi Goldberg",
    bunk: "2",
    balance: "$" + (75).toFixed(2),
    method: "Cash",
    id: 1002,
    notes: "Gave cash to canteen manager",
  },
  {
    name: "Yossi Zaks",
    bunk: "1a",
    balance: "$" + (120).toFixed(2),
    method: "Cash",
    id: 1003,
    notes: "Bunk list first day",
  },
  {
    name: "Izzy Fine",
    bunk: "16",
    balance: "$" + (40).toFixed(2),
    method: "Website",
    id: 1004,
    notes: "",
  },
  {
    name: "Mendel Cohen",
    bunk: "16",
    balance: "$" + (80).toFixed(2),
    method: "Cash",
    id: 1005,
    notes: "Paid through Tuition CAD",
  },
];

export const sampleStaffWithoutId = [
  {
    name: "Samy Cohen",
    spendingLimit: "$" + (1000).toFixed(2),
    notes: "Waiter",
  },
  {
    name: "Levi Goldberg",
    spendingLimit: "$" + (375).toFixed(2),
    notes: "Life Guard",
  },
  {
    name: "Yossi Zaks",
    spendingLimit: "$" + (500).toFixed(2),
    notes: "1D",
  },
  {
    name: "Izzy Fine",
    spendingLimit: "$" + (250).toFixed(2),
    notes: "Sports Director",
  },
  {
    name: "Mendel Cohen",
    spendingLimit: "$" + (2500).toFixed(2),
    notes: "",
  },
];

export const sampleStaffWithId = [
  {
    name: "Samy Cohen",
    spendingLimit: "$" + (1000).toFixed(2),
    id: 1001,
    notes: "Waiter",
  },
  {
    name: "Levi Goldberg",
    spendingLimit: "$" + (375).toFixed(2),
    id: 1002,
    notes: "Life Guard",
  },
  {
    name: "Yossi Zaks",
    spendingLimit: "$" + (500).toFixed(2),
    id: 1003,
    notes: "1D",
  },
  {
    name: "Izzy Fine",
    spendingLimit: "$" + (250).toFixed(2),
    id: 1004,
    notes: "Sports Director",
  },
  {
    name: "Mendel Cohen",
    spendingLimit: "$" + (2500).toFixed(2),
    id: 1005,
    notes: "",
  },
];

export const sampleCostWithoutId = [
  {
    name: "Samy Cohen",
    salary: "$" + (5000).toFixed(2),
    notes: "Admin",
  },
  {
    name: "Levi Goldberg",
    salary: "$" + (3000).toFixed(2),
    notes: "Head Life Guard",
  },
  {
    name: "Yossi Zaks",
    salary: "$" + (1500).toFixed(2),
    notes: "Head Counselor",
  },
  {
    name: "Izzy Fine",
    salary: "$" + (5000).toFixed(2),
    notes: "Camp Director",
  },
  {
    name: "Mendel Cohen",
    salary: "$" + (2500).toFixed(2),
    notes: "",
  },
];

export const sampleCostWithId = [
  {
    name: "Samy Cohen",
    salary: "$" + (5000).toFixed(2),
    id: 1001,
    notes: "Admin",
  },
  {
    name: "Levi Goldberg",
    salary: "$" + (3000).toFixed(2),
    id: 1002,
    notes: "Head Life Guard",
  },
  {
    name: "Yossi Zaks",
    salary: "$" + (1500).toFixed(2),
    id: 1003,
    notes: "Head Counselor",
  },
  {
    name: "Izzy Fine",
    salary: "$" + (5000).toFixed(2),
    id: 1004,
    notes: "Camp Director",
  },
  {
    name: "Mendel Cohen",
    salary: "$" + (2500).toFixed(2),
    id: 1005,
    notes: "",
  },
];

export const getSampleData = (tabType, autoGenerateId) => {
  if (tabType === "camper") {
    return autoGenerateId ? sampleCamperWithoutId : sampleCamperWithId;
  } else if (tabType === "staff") {
    return autoGenerateId ? sampleStaffWithoutId : sampleStaffWithId;
  } else if (tabType === "cost") {
    return autoGenerateId ? sampleCostWithoutId : sampleCostWithId;
  }
  return [];
};
