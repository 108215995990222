import { storage } from "../db/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { getBlobUrl } from "../utils";

export const getAllCategories = async () => {
  try {
    const data = await getPredefinedCategories();
    return data;
  } catch (error) {
    throw error;
  }
};

const toTitleCase = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const getPredefinedCategories = async () => {
  const categoriesRef = ref(storage, "categories/");
  const categoriesSnapshot = await listAll(categoriesRef);
  const predefinedCategories = await Promise.all(
    categoriesSnapshot.items.map(async (item, ind) => {
      const downloadUrl = await getDownloadURL(item);
      const blobUrl = await getBlobUrl(downloadUrl);
      const categoryName = toTitleCase(
        item.name.replace(".png", "").replace(/-/g, " ").replace(/_/g, " ")
      );
      return {
        categoryName,
        categoryImgUrl: downloadUrl,
        ind: ind,
        categoryBlobUrl: blobUrl,
      };
    })
  );
  return predefinedCategories;
};
