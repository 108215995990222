import { React, createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { ref, onValue, off, update } from "firebase/database";
import { db, realtimeDb } from "../db/firebase";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import { getAllCategories } from "../api/category";
import useInactivityLogout from "../hooks/useInactivityLogout";
import { getBlobUrl } from "../utils";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem("token") || null);
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(false);
  const { data: categoryIcons } = useQuery({
    queryKey: ["categoryIcons"],
    queryFn: getAllCategories,
    staleTime: Infinity,
  });
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [dashboardOrders, setDashboardOrders] = useState({});
  const [dashboardTopProducts, setDashboardTopProducts] = useState([]);
  const [dashboardLifeTime, setDashboardLifeTime] = useState({});
  const [dashboardSalesAndProfits, setDashboardSalesAndProfits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalOrdersPages, setTotalOrdersPages] = useState(0);
  const [currentOrdersPage, setCurrentOrdersPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [currentTransactionsPage, setCurrentTransactionsPage] = useState(1);
  const [totalTransactionsPages, setTotalTransactionsPages] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [currentAccountsPage, setCurrentAccountsPage] = useState(1);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalAccountsPages, setTotalAccountsPages] = useState(0);
  const [monthlySalesAndProfit, setMonthlySalesAndProfit] = useState([]);
  const [totalCashinCamper, setTotalCashinCamper] = useState();
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  const currentDate = new Date();

  const addDefaultBunk = async (userId, token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/setting/addBunk`,
        { userId, bunkNumber: "1" },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.success) {
        return [{ bunkNumber: "1" }];
      }
    } catch (error) {
      console.error("Failed to add default bunk:", error);
    }
    return [];
  };

  const loginUser = async (userName, password) => {
    setLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/login`,
        {
          userName,
          password,
        }
      );
      const token = result.data.token;
      let user = result.data.data;
      setToken(token);

      if (!user.bunks || user.bunks.length === 0) {
        const defaultBunks = await addDefaultBunk(user.id, token);
        user = { ...user, bunks: defaultBunks };
      }
      setUser(user);

      return result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = async () => {
    if (user && user.id) {
      const userRef = ref(realtimeDb, `users/${user.id}`);
      try {
        await update(userRef, {
          tokenExpired: false,
          logoutAllPosDevices: false,
        });
      } catch (error) {
        console.error("Failed to logout", error);
      }
    }
    setToken(null);
    setUser(null);
    setInitialFetchDone(false);
    sessionStorage.clear();
    navigate("/");
  };

  useInactivityLogout(logoutUser);

  // const fetchOrders = async () => {
  //   try {
  //     setLoading(true);
  //     const payload = { userId: user.id };
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_HOST_URL}/api/pos/order/getAll`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json;charset=UTF-8",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setOrders(response.data.data);
  //   } catch (error) {
  //     console.error("Failed to fetch orders:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchDashboardOrders = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/orders`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDashboardOrders(response.data.data);
    } catch (error) {
      console.error("Failed to fetch top products and lifetime:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardLifeTime = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/lifeTime`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDashboardLifeTime(response.data.data);
    } catch (error) {
      console.error("Failed to fetch top products and lifetime:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardSalesAndProfits = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/salesAndProfits`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const ordersSnapshot = response.data.ordersData;

      let dashboardSalesAndProfit = [
        { day: "Sun", sales: 0, profits: 0 },
        { day: "Mon", sales: 0, profits: 0 },
        { day: "Tue", sales: 0, profits: 0 },
        { day: "Wed", sales: 0, profits: 0 },
        { day: "Thu", sales: 0, profits: 0 },
        { day: "Fri", sales: 0, profits: 0 },
        { day: "Sat", sales: 0, profits: 0 },
      ];

      ordersSnapshot.forEach((order) => {
        const orderDate = new Date(order.orderedTime.seconds * 1000);
        const dayIndex = orderDate.getDay();

        dashboardSalesAndProfit[dayIndex].sales += order.total;
        dashboardSalesAndProfit[dayIndex].profits += order.profit;
      });

      dashboardSalesAndProfit = dashboardSalesAndProfit.map((day) => ({
        ...day,
        sales: Number(day.sales.toFixed(2)),
        profits: Number(day.profits.toFixed(2)),
      }));

      setDashboardSalesAndProfits(dashboardSalesAndProfit);
    } catch (error) {
      console.error("Failed to fetch sales and profits:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalCashinCamper = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/totalCamperCash`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalCashinCamper(response.data.data.totalCash);
    } catch (error) {
      console.error("Failed to fetch total cash in:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardTopProducts = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/topProducts`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDashboardTopProducts(response.data.data);
    } catch (error) {
      console.error("Failed to fetch top products and lifetime:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/category/getAll`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const categories = response.data.data;

      const categoriesWithBlobUrls = await Promise.all(
        categories.map(async (category) => {
          const blobUrl = await getBlobUrl(category.categoryImgUrl);

          return {
            ...category,
            categoryBlobUrl: blobUrl,
          };
        })
      );

      const sortedCategories = categoriesWithBlobUrls.sort(
        (a, b) => a.pos - b.pos
      );

      setCategories(sortedCategories);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/getAll`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedProducts = response.data.data.sort((a, b) => a.pos - b.pos);
      setProductsData(sortedProducts);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchAccounts = async () => {
  //   try {
  //     setLoading(true);
  //     const payload = { userId: user.id };
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_HOST_URL}/api/pos/account/getAll`,
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json;charset=UTF-8",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setAccounts(response.data.data);
  //   } catch (error) {
  //     console.error("Failed to fetch accounts:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchAccountsWithPaginations = async (pageNumber = 1, perPage = 20) => {
    try {
      setLoading(true);
      const payload = {
        userId: user.id,
        pageNumber: pageNumber,
        perPage: perPage,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/getAccountsPerPage`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);

      setAccounts(response.data.data.accounts);
      setCurrentAccountsPage(response.data.data.currentPage);
      setTotalAccountsPages(response.data.data.totalPages);
      setTotalAccounts(response.data.data.totalAccounts);
    } catch (error) {
      console.error("Failed to fetch accounts:", error);
    } finally {
      setLoading(false);
    }
  };

  const ordersWithPagination = async (page, limit) => {
    try {
      const payload = { userId: user.id, pageNumber: page, perPage: limit };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/order/findOrders`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(response.data.data.orders);
      setCurrentOrdersPage(response.data.data.currentPage);
      setTotalOrdersPages(response.data.data.totalPages);
      setTotalOrders(response.data.data.totalOrders);
    } catch (error) {
      console.error("Failed to fetch orders with pagination:", error);
    }
  };

  const fetchTransactions = async (pageNumber = 1, perPage = 20) => {
    try {
      const payload = {
        userId: user.id,
        pageNumber: pageNumber,
        perPage: perPage,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/order/transactions`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(response.data.data.transactions);
      setCurrentTransactionsPage(response.data.data.currentPage);
      setTotalTransactionsPages(response.data.data.totalPages);
      setTotalTransactions(response.data.data.totalTransactions);

      console.log(response.data.data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    }
  };

  const fetchMonthlySalesAndProfit = async (month, year) => {
    try {
      setLoading(true);
      const payload = { userId: user.id, month, year };
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard//monthlySalesAndProfit`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMonthlySalesAndProfit(response.data.data);
    } catch (error) {
      console.error("Failed to fetch monthly sales and profit:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && user && !initialFetchDone) {
      // fetchOrders();
      ordersWithPagination(currentOrdersPage, 20);
      fetchDashboardOrders();
      fetchDashboardLifeTime();
      fetchDashboardSalesAndProfits();
      fetchDashboardTopProducts();
      fetchCategories();
      fetchProducts();
      // fetchAccounts();
      setInitialFetchDone(true);
      fetchTotalCashinCamper();
      fetchTransactions();
      fetchAccountsWithPaginations();
    }
  }, [token, user, initialFetchDone]);

  useEffect(() => {
    if (token && user) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("user", JSON.stringify(user));

      const userRef = ref(realtimeDb, `users/${user.id}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (data.tokenExpired || data.deleted || data.logoutAllPosDevices) {
            logoutUser();
          }
        }
      });

      return () => {
        off(userRef);
      };
    } else {
      sessionStorage.clear();
    }
  }, [token, user]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      const remainingTime = expiryTime - Date.now();

      if (remainingTime > 0) {
        const timer = setTimeout(() => {
          logoutUser();
        }, remainingTime);

        return () => clearTimeout(timer);
      } else {
        logoutUser();
      }
    }
  }, [token]);

  const authValue = {
    token,
    user,
    loginUser,
    logoutUser,
    setUser,
    loading,
    categoryIcons,
    orders,
    setOrders,
    categories,
    setCategories,
    productsData,
    setProductsData,
    accounts,
    setAccounts,
    ordersWithPagination,
    totalOrdersPages,
    currentOrdersPage,
    setCurrentOrdersPage,
    totalOrders,
    dashboardOrders,
    dashboardTopProducts,
    dashboardLifeTime,
    dashboardSalesAndProfits,
    monthlySalesAndProfit,
    fetchMonthlySalesAndProfit,
    totalCashinCamper,
    transactions,
    totalTransactions,
    totalTransactionsPages,
    currentTransactionsPage,
    setTransactions,
    fetchTransactions,
    setCurrentTransactionsPage,
    totalAccounts,
    totalAccountsPages,
    currentAccountsPage,
    fetchAccountsWithPaginations,
    setCurrentAccountsPage,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
