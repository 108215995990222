import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import campposLogo from "../../../assets/campposFooterLogo.png";

Font.register({
  family: "Poppins",
  fonts: [
    { src: "/fonts/Poppins-Bold.ttf", fontWeight: "bold" },
    { src: "/fonts/Poppins-Medium.ttf", fontWeight: "medium" },
    { src: "/fonts/Poppins-Light.ttf", fontWeight: "normal" },
  ],
});

Font.register({
  family: "Nunito",
  fonts: [{ src: "/fonts/Nunito-SemiBold.ttf", fontWeight: "semibold" }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingTop: 23,
    paddingBottom: 50,
  },
  header: {
    marginBottom: 20,
    alignItems: "center",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 27,
    fontWeight: "bold",
    textAlign: "center",
    color: "#12141E",
    marginBottom: "2px",
    textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "Nunito",
    fontSize: 16,
    fontWeight: "semibold",
    textAlign: "center",
    color: "#CDCBCA",
  },
  menuGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingHorizontal: 30,
    gap: 25,
    marginBottom: 50,
  },
  menuColumn: {
    width: "30%",
    minWidth: 140,
  },
  menuItem: {
    width: "100%",
    height: "auto",
    marginBottom: 25,
  },
  categoryContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 23,
    gap: 0,
    borderRadius: 90,
    borderWidth: 1,
    borderColor: "#E66E27",
    backgroundColor: "#FCF1E9",
    position: "relative",
  },
  categoryText: {
    fontFamily: "Nunito",
    fontSize: 12,
    fontWeight: "semibold",
    color: "#704332",
    textTransform: "capitalize",
  },
  categoryImage: {
    width: 34,
    height: 34,
    position: "absolute",
    left: -10,
    opacity: 1,
  },
  productContainer: {
    marginTop: 17,
    marginBottom: 10,
    flexDirection: "column",
    gap: 10,
  },
  productRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
  },
  productText: {
    fontFamily: "Poppins",
    fontWeight: "medium",
    fontSize: 11,
    color: "#12141E",
    maxWidth: "70%",
  },
  dot: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#12141E",
    borderBottom: "2px dotted #12141E",
    flexGrow: 1,
    marginBottom: 5,
  },
  productCost: {
    fontFamily: "Poppins",
    fontWeight: "medium",
    fontSize: 11,
    color: "#12141E",
  },
  footer: {
    backgroundColor: "#E06F2C",
    color: "white",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerContent: {
    display: "flex",
    flexDirection: "row",
  },
  footerText: {
    fontFamily: "Poppins",
    fontWeight: "normal",
    fontSize: 10,
    color: "white",
  },
  footerLogo: {
    width: 57,
    height: 17,
  },
});

const MyDocument = ({ categoryData, title, subTitle }) => {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const columns = Array.from({ length: 3 }, () => []);

  categoryData.forEach((category, index) => {
    columns[index % 3].push(category);
  });

  return (
    <Document>
      <Page size={{ width: 8.5 * 72, height: 11 * 72 }} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>{title} Menu</Text>
          <Text style={styles.subtitle}>{subTitle}</Text>
        </View>

        <View style={styles.menuGrid}>
          {columns.map((column, colIndex) => (
            <View key={colIndex} style={styles.menuColumn}>
              {column.map((category, index) => (
                <View key={index} style={styles.menuItem} wrap={true}>
                  <View style={styles.categoryContainer}>
                    <Image
                      src={category.categoryImage}
                      style={styles.categoryImage}
                    />
                    <Text style={styles.categoryText}>
                      {category.categoryName}
                    </Text>
                  </View>
                  <View style={styles.productContainer}>
                    {category.products.map((product, index) => (
                      <View style={styles.productRow} key={index}>
                        <Text style={styles.productText}>
                          {/* {truncateText(product.name, 15)} */}
                          {product.name}
                        </Text>
                        <Text style={styles.dot} />
                        <Text style={styles.productCost}>${product.cost}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          ))}
        </View>

        <View style={styles.footer} fixed>
          <View style={styles.footerContent}>
            <Text style={styles.footerText}>
              This menu was automatically generated by:
            </Text>
            <Image style={styles.footerLogo} src={campposLogo} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
