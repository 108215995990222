import React from "react";

const SampleTable = ({ data }) => {
  const columns = Object.keys(data[0]);

  return (
    <div className="w-full overflow-x-auto rounded-[5px]">
      <table className="w-full bg-white text-[#12141E] text-left border-collapse">
        <thead className="text-xs capitalize bg-[#EFEFEF] h-[31.7px] leading-[18px]">
          <tr>
            {columns.map((column, index) => (
              <th 
                key={index} 
                className={`px-6 font-semibold tracking-wider border-r border-gray-550 ${
                  index === columns.length - 1 ? 'border-r-0' : ''
                }`}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="h-[35px] text-[11.5px] font-normal leading-[17.25px]"
            >
              {columns.map((column, colIndex) => (
                <td 
                  key={colIndex} 
                  className={`px-6 whitespace-nowrap border-r border-gray-550 ${
                    colIndex === columns.length - 1 ? 'border-r-0' : ''
                  }`}
                >
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SampleTable;