import { Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";


function DropdownList({ value, items, titleWidth, isLoading, data, setData }) {
  const [title, setTitle] = useState(value);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setTitle(value);
  }, [value]);

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(18, 20, 30, 1)",
  };

  const handleSelect = (item) => {
    setTitle(item.categoryName);
    setSelectedItem(item.categoryId);
    
    setData({ ...data, categoryId: item.categoryId });
  };

  return (
    <Dropdown className="custom-dropdown object-cover">
      <Dropdown.Toggle
        id="dropdown-basic"
        style={{
          backgroundColor: "rgba(248, 248, 248, 1)",
          ...normalStyle,
          border: "none",
          width: titleWidth,
        }}
      >
        {data.categoryId === "" ? "Choose" : title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ backgroundColor: "rgba(248, 248, 248, 1)", border: "none" }}
      >
        {isLoading ? (
          <div className="d-flex items-center justify-center">
            <Spinner color="#FF8943" />
          </div>
        ) : (
          items.map((item, index) => (
            <React.Fragment key={item.categoryId}>
              <Dropdown.Item
                style={{
                  ...normalStyle,
                  backgroundColor: `${
                    selectedItem === item.categoryId
                      ? "rgba(224, 111, 44, 1)"
                      : ""
                  }`,
                  borderRadius: selectedItem === item.categoryId ? "10px" : "0",
                  color: selectedItem === item.categoryId ? "#f8f8f8" : "",
                }}
                onClick={() => handleSelect(item)}
              >
                {item.categoryName}
              </Dropdown.Item>
              {index < items.length - 1 && (
                <hr
                  style={{
                    width: "80%",
                    margin: "5px auto",
                    border: "none",
                    borderBottom: "2px solid #D7D7D7",
                  }}
                />
              )}
            </React.Fragment>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownList;
