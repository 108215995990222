import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TbSortAscendingNumbers, TbSortDescendingNumbers  } from "react-icons/tb";

function DropdownList({ items, style, onSelect, order }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(18, 20, 30, 1)",
  };

  const handleSelect = (newItem) => {
    setSelectedItem(newItem);
    onSelect(newItem);
  };

  return (
    <Dropdown className="custom-dropdown object-cover">
      <Dropdown.Toggle
        id="dropdown-basic"
        style={{
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
            display: "flex",
            alignItems: "center",
          }}
      >
        <div
          className="d-flex flex-row items-center gap-[5px]"
          style={style}
        >
          Sort By { selectedItem && (order === "DSC" ? <TbSortAscendingNumbers /> : <TbSortDescendingNumbers />)}
          <MdOutlineKeyboardArrowDown className="w-6 h-6 text-gray-950" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ backgroundColor: "rgba(248, 248, 248, 1)", border: "none" }}
      >
        {items.map((item, index) => (
          <React.Fragment key={item.value}>
            <Dropdown.Item
              style={{
                ...normalStyle,
                backgroundColor: `${
                  selectedItem === item.value ? "rgba(224, 111, 44, 1)" : ""
                }`,
                borderRadius: selectedItem === item.value ? "10px" : "0",
                color: selectedItem === item.value ? "#f8f8f8" : "",
              }}
              onClick={() => handleSelect(item.value)}
            >
              {item.label}
            </Dropdown.Item>
            {index < items.length - 1 && (
              <hr
                style={{
                  width: "80%",
                  margin: "5px auto",
                  border: "none",
                  borderBottom: "2px solid #D7D7D7",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownList;
