import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const AccountDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.value || null);

  useEffect(()=>{
    setSelectedOption(props.value);
  },[props.value])

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (item) => () => {
    setSelectedOption(item.label);
    props.setAccountType(item.label)
    setIsOpen(false);
    if (props.onChange) {
      props.onChange(item.label);
    }
  };

  return (
    <div className="flex w-full flex-col">
      
      <div
        className="d-flex justify-center relative inline-flex bg-whitesmoke-100 w-[90%]"
        style={{ borderRadius: "5px" }}
      >
        <div
          className="w-4/5 px-2 py-2 cursor-pointer"
          onClick={handleToggle}
          style={{ color: "#000000" }}
        >
          {selectedOption || "Select"}
        </div>
        <div
          className="absolute right-5 top-1/2 transform -translate-y-1/2 h-6 w-6 flex justify-center items-center cursor-pointer"
          onClick={handleToggle}
        >
          <IoIosArrowDown style={{ color: "#000000" }} />
        </div>

        {isOpen && (
          <div className="absolute top-full left-0 z-10 mt-1 w-full origin-top-right rounded-md bg-whitesmoke-100 max-h-60 overflow-y-auto custom-scrollbar">
            {props.items.map((item) => (
              <button
                type="button"
                onClick={onOptionClicked(item)}
                key={item.value}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-500 focus:outline-none"
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDropdown;
