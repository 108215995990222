import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cross from "../../assets/Cross.svg";
import plusCircle from "../../assets/plusCircle.svg";
import AddOnInput from "../Common/addOnInpurt";

function ProductAddOnModal(props) {
  const [addOns, setAddOns] = useState(
    props.data.addOns || [
      {
        id: 1,
        name: "",
        price: 0,
      },
    ]
  );

  const boldStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    lineHeight: "24.75px",
    color: "rgba(18, 20, 30, 1)",
    backgroundColor: "#F8F8F8",
  };

  const handleAddNewAddOn = () => {
    const newId = Math.max(...addOns.map((addon) => addon.id), 0) + 1;
    setAddOns([...addOns, { id: newId, name: "", price: 0 }]);
  };

  const handleDeleteAddOn = (id) => {
    setAddOns(addOns.filter((addon) => addon.id !== id));
  };

  const handleUpdateAddOn = (id, updatedData) => {
    setAddOns(
      addOns.map((addon) =>
        addon.id === id ? { ...addon, ...updatedData } : addon
      )
    );
  };

  const handleSaveAddOns = () => {
    // Validate add-ons before saving
    const validAddOns = addOns.filter((addon) => addon.name.trim() !== "");

    // You can pass the validated add-ons back to the parent component
    props.setData((prevData) => ({
      ...prevData,
      addOns: addOns,
    }));

    // Optionally close the modal
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="custom-modal"
        centered
      >
        <div className="d-flex flex-column pe-6 ps-10 pb-6">
          <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
            <div
              style={{
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "31.5px",
              }}
            >
              Product Add-ons
            </div>
            <button
              className="w-[15.5px] h-[15.5px] me-[23px]"
              onClick={props.handleClose}
            >
              <img src={Cross} alt="cross" />
            </button>
          </div>
          <div className="text-xs text-[#00000047] mb-6">
            {props.data.productName}
          </div>

          {addOns.map((addon) => (
            <AddOnInput
              key={addon.id}
              id={addon.id}
              addOn={addon}
              onDelete={handleDeleteAddOn}
              onUpdate={handleUpdateAddOn}
            />
          ))}

          <div
            className="ml-6 mt-3 flex gap-2 items-center cursor-pointer"
            onClick={handleAddNewAddOn}
          >
            <img src={plusCircle} alt="circles" className="w-4 h-4" />
            <div className="text-[15px] font-[500]">Add On</div>
          </div>
          <div className="d-flex justify-center items-center h-[30px] mt-8">
            <button
              className="h-[30px] w-[94px]"
              style={{
                backgroundColor: "#FF8943",
                borderRadius: "5px",
                color: "#fff",
              }}
              onClick={handleSaveAddOns}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .custom-modal .modal-content {
          border-radius: 20px;
          width: 900px;
        }

        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
    </>
  );
}

export default ProductAddOnModal;
